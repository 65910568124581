import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import ShareIcon from '@mui/icons-material/Share';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Links from './views/Links';
import Hotspot from './views/Hotspot';
import Device from './views/Device';
import Social from './views/Social';
import Data from './views/Data';
import Summary from './views/Summary';
import Print from './views/Print';
import { format } from 'date-fns';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import buildUrl from '../buildUrl';
import useApi from '../hooks/useApi';

const reducer = (prevState, newState) => {
    let { campaign, isLoading, isInitialising, campaignSummary, groups, selectedGroup, tab, subscriberStatus, linkId, snackbarMessage } = { ...prevState, ...newState };

    if (campaign !== prevState.campaign) {
        selectedGroup = 'none';
    }

    if (campaign && campaignSummary && groups) {
        isLoading = false;
        isInitialising = false;
    }

    if (!isInitialising && linkId && (linkId !== prevState.linkId)) {
        tab = 2;
    }

    return {
        campaign,
        isLoading,
        isInitialising,
        campaignSummary,
        groups,
        selectedGroup,
        tab,
        subscriberStatus,
        linkId,
        snackbarMessage
    };
};

const initialState = {
    campaign: null,
    isLoading: false,
    isInitialising: true,
    campaignSummary: null,
    groups: null,
    selectedGroup: 'none',
    tab: 0,
    subscriberStatus: 'selected',
    linkId: null,
    snackbarMessage: null
};

const titleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const appBarStyle = {
    top: 64,
    '@media print': {
        position: 'relative',
        top: 0,
        display: 'block',
        boxShadow: 'none',
        borderBottom: '1px solid #EEE'
    }
};

const Subtitle = ({ campaign }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const sendDateTime = format((new Date(campaign.sendDateTime)), 'dd/MM/yyyy HH:mm');
    const statusAndDate = `${campaign.status} ${sendDateTime}`;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getSubtitle = () => {
        if (campaign.allSubscribers) {
            return `${statusAndDate} to All Contacts`;
        }

        if (campaign.segment) {
            return `${statusAndDate} to 1 segment`;
        }

        if (campaign.groups && campaign.groups.length > 0) {
            if (campaign.groups.length === 1) {
                const groupName = campaign.groups[0].name;
                if (groupName === 'On-the-fly Group') return `${statusAndDate} to Manual List`;
                if (groupName === 'Proof') return `${statusAndDate} to Proof Group`;
                return `${statusAndDate} to 1 group`;
            }

            return `${statusAndDate} to ${campaign.groups.length} groups`;
        }

        return statusAndDate;
    };

    const showMoreInfoChecks = () => {
        if (campaign.allSubscribers) {
            return false;
        }

        if (campaign.groups && campaign.groups.length === 1) {
            if (campaign.groups[0].name === 'Proof' || campaign.groups[0].name === 'On-the-fly Group') {
                return false;
            }
            else {
                return true;
            }
        }

        return true;
    };

    const showMoreInfo = showMoreInfoChecks();

    const getPopoverContent = () => {
        if (showMoreInfo) {

            if (campaign.segment) {
                return `Segment: ${campaign.segment.name}`;
            }

            if (campaign.groups && campaign.groups.length > 0) {

                if (campaign.groups.length === 1) {
                    return `Group: ${campaign.groups[0].name}`;
                }
                else {
                    return (
                        <List
                            dense
                            disablePadding
                            subheader="Groups:"
                        >
                            {campaign.groups.map((g, i) => (
                                <ListItem disablePadding key={i}>
                                    <ListItemText primary={g.name} />
                                </ListItem>
                            ))}
                        </List>
                    );
                }
            }
        }

        return 'No extra information available';
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <>
            <Typography component="h2" variant="subtitle1" sx={titleStyle}>
                {getSubtitle()}
                {(campaign && showMoreInfo) && (
                    <IconButton
                        onClick={handleClick}
                        sx={{ marginLeft: 1 }}
                        color="info"
                    >
                        <InfoIcon fontSize="small" />
                    </IconButton>
                )}
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Typography sx={{ p: 2 }}>
                    {getPopoverContent()}
                </Typography>
            </Popover>
        </>
    );
};

const ShareButton = ({ shareLink, setSnackbarMessage }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setSnackbarMessage('Share link copied to clipboard');
    };

    const renderField = (value, label) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    value={value}
                    label={label}
                    onFocus={e => e.target.select()}
                    InputProps={{
                        readOnly: true
                    }}
                    sx={{ flex: 1 }}
                />
                <IconButton
                    aria-label="copy to clipboard"
                    onClick={() => copyToClipboard(value)}
                    sx={{ flex: 0, marginLeft: 1 }}
                >
                    <ContentPasteIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <>
            <IconButton onClick={handleClick} edge="end" size="large">
                <ShareIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Typography>Share your campaign online:</Typography>
                    {renderField(`${shareLink}?type=facebook`, 'Facebook')}
                    {renderField(`${shareLink}?type=twitter`, 'Twitter')}
                    {renderField(`${shareLink}?type=linkedin`, 'LinkedIn')}
                    {renderField(shareLink, 'Share Link')}
                </Box>
            </Popover>
        </>
    );
};

const Campaign = () => {
    const { campaignId } = useParams();
    const [state, setState] = useReducer(reducer, initialState);
    const { handleGet } = useApi();

    const sentDate = state.campaign ? format((new Date(state.campaign.sendDateTime)), 'dd-MM-yyyy HHmm') : '-';

    const handleLoadCampaign = async () => {
        const response = await handleGet(`reports/${campaignId}`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setState({ campaign: data });
    };

    const handleLoadCampaignSummary = async () => {
        const url = buildUrl(`reports/${campaignId}/summary`, {
            ...(state.selectedGroup !== 'none' && { groupId: state.selectedGroup })
        });

        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setState({ campaignSummary: data });
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setState({ groups: data });
    };

    const handleViewWhoClicked = linkId => {
        setState({ linkId });
    };

    const handleChangeSubscriberStatusFilter = subscriberStatus => {
        setState({ tab: 3, subscriberStatus });
    };

    const handleSetDocumentTitle = () => {
        document.title = `Campaign Report for '${state.campaign?.subject}' - Sent ${sentDate}`;
    };

    const handleUnSetDocumentTitle = () => {
        document.title = 'NewZapp';
    };

    useEffect(() => {
        if (!state.campaign) {
            return;
        }

        window.addEventListener('beforeprint', () => {
            handleSetDocumentTitle();
        });

        window.addEventListener('afterprint', () => {
            handleUnSetDocumentTitle();
        });

        return () => {
            window.removeEventListener('beforeprint', () => {
                handleSetDocumentTitle();
            });

            window.removeEventListener('afterprint', () => {
                handleUnSetDocumentTitle();
            });
        };
    }, [state.campaign]);

    useEffect(() => {
        if (!state.isInitialising) {
            setState({ isLoading: true, campaign: null, campaignSummary: null });
        }

        handleLoadCampaign();
        handleLoadCampaignSummary();
        handleFetchGroups();
    }, [campaignId]);

    useEffect(() => {
        if (state.isInitialising) return;

        setState({ isLoading: true, campaignSummary: null });

        handleLoadCampaignSummary();
    }, [state.selectedGroup]);

    if (state.isLoading || state.isInitialising) {
        return (
            <LoadingOverlay />
        );
    }

    const Header = () => {
        const title = state.campaign.subject;

        return (
            <AppBar
                position="sticky"
                color="inherit"
                sx={appBarStyle}
            >
                <Toolbar variant="dense" sx={{ overflow: 'hidden', pt: 2 }}>
                    <Stack>
                        <Typography component="h1" variant="h6" sx={titleStyle}>{state.campaign.name}</Typography>
                        <Typography component="h2" variant="body1" sx={titleStyle}>Subject: {title}</Typography>
                    </Stack>
                </Toolbar>
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Subtitle campaign={state.campaign} />
                    </Box>
                    <Box sx={{ whiteSpace: 'nowrap', paddingLeft: 2 }}>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('sent')}>{state.campaign.sent} Sent</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('opened')}>{state.campaign.uniqueOpens} Unique Opens</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('clicked')}>{state.campaign.uniqueClicks} Unique Clicks</Button>
                        <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('bounced')}>{state.campaign.bounced} Bounced</Button>
                        {state.campaign.failed > 0 && <Button variant="text" onClick={() => handleChangeSubscriberStatusFilter('failed')}>{state.campaign.failed} Failed</Button>}
                        <ShareButton
                            shareLink={state.campaign.shareLink}
                            setSnackbarMessage={snackbarMessage => setState({ snackbarMessage })}
                        />
                    </Box>
                </Toolbar>
                <Tabs value={state.tab} onChange={(e, tab) => setState({ tab })}>
                    <Tab label="Summary" />
                    <Tab label="Hotspot" />
                    <Tab label="Links" />
                    <Tab label="Data" />
                    <Tab label="Device" />
                    <Tab label="Social" />
                    {/*<Tab label="Analytics" />*/}
                    <Tab label="Print" />
                </Tabs>
            </AppBar>
        );
    };

    return (
        <>
            <Header />
            <Box sx={{ overflow: 'hidden' }}>
                {state.tab === 0 && (
                    <Summary
                        campaign={state.campaign}
                        isTabSelected={state.tab === 0}
                        onChangeSubscriberStatusFilter={subscriberStatus => handleChangeSubscriberStatusFilter(subscriberStatus)}
                    />
                )}
                {state.tab === 1 && (
                    <Hotspot
                        campaignId={state.campaign.id}
                        onViewWhoClicked={handleViewWhoClicked}
                    />
                )}
                {state.tab === 2 && (
                    <Links
                        campaignId={state.campaign.id}
                        onViewWhoClicked={handleViewWhoClicked}
                        linkId={state.linkId}
                    />
                )}
                {state.tab === 3 && (
                    <Data
                        campaignId={state.campaign.id}
                        groups={state.groups}
                        subscriberStatus={state.subscriberStatus}
                        setSubscriberStatus={subscriberStatus => setState({ subscriberStatus })}
                        selectedGroup={state.selectedGroup}
                        setSelectedGroup={selectedGroup => setState({ selectedGroup })}
                        campaignSummary={state.campaignSummary}
                    />
                )}
                {state.tab === 4 && <Device campaignId={state.campaign.id} />}
                {state.tab === 5 && (
                    <Social
                        campaignId={state.campaign.id}
                        campaignUniqueOpens={state.campaign.uniqueOpens}
                    />
                )}
                {/*{tab === 6 && (*/}
                {/*    <Analytics*/}
                {/*        gaCampaign={campaign.gaCampaign}*/}
                {/*        sendDate={campaign.sendDateTime}*/}
                {/*    />*/}
                {/*)}*/}
                {state.tab === 6 && <Print campaign={state.campaign} />}
            </Box>
            <Snackbar
                open={Boolean(state.snackbarMessage)}
                onClose={() => setState({ snackbarMessage: null })}
                autoHideDuration={3000}
                message={state.snackbarMessage}
            />
        </>
    );
};

export default Campaign;