import React, { useState, useEffect } from 'react';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import TitleBar from '../../titleBar/TitleBar';
import useApi from '../../hooks/useApi';
import Button from '@mui/material/Button';
import DeleteNotificationsDialog from './dialogs/DeleteNotificationsDialog';
import CreateNotificationDialog from './dialogs/CreateNotificationDialog';
import EditNotificationDialog from './dialogs/EditNotificationDialog';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useSnackbar from '../../hooks/useSnackbar';
import buildUrl from '../../buildUrl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const Notifications = ({ showHamburger, onClickHamburger }) => {
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [showDeleteNotificationsDialog, setShowDeleteNotificationsDialog] = useState(false);
    const [showCreateNotificationDialog, setShowCreateNotificationDialog] = useState(false);
    const [showEditNotificationDialog, setShowEditNotificationDialog] = useState(false);
    const { showSnackbar } = useSnackbar();

    const handleFetchNotifications = async () => {
        const response = await handleGet('superadmin/notifications');
        const data = await response.json();
        setNotifications(data);
        setIsLoading(false);
    };

    const showEditNotificationModal = (notification) => {
        setShowEditNotificationDialog(true);
        setSelectedNotification(notification);
    };

    useEffect(() => {
        handleFetchNotifications();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    // Select items
    const handleSelectNotification = (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedNotifications(selectedNotifications.includes(id) ? selectedNotifications.filter(a => a !== id) : [...selectedNotifications, id]);
    };

    //Delete notifications functions
    const handleDeleteNotifications = async () => {
        const ids = [...selectedNotifications];
        setSelectedNotifications([]);
        setShowDeleteNotificationsDialog(false);

        const url = buildUrl('superadmin/notifications', {
            ...(ids && { ids })
        });

        await handleDelete(url);
        await handleFetchNotifications();
    };

    //Create notification
    const handleCreateNotification = async ({ date, subject, body, linkText, linkUrl }) => {
        setShowCreateNotificationDialog(false);
        setIsLoading(true);

        const response = await handlePost('superadmin/notifications', {
            date,
            subject,
            body,
            linkText,
            linkUrl
        });

        if (!response.ok) {
            showSnackbar('Notification creation failed: Invalid data', 'error');
            setIsLoading(false);
            return;
        }

        handleFetchNotifications();
    };

    //Edit notification
    const handleEditNotification = async (notification) => {
        setIsLoading(true);

        const url = `/superadmin/notifications/${notification.id}`;

        const response = await handlePut(url, notification);

        setIsLoading(false);

        if (!response.ok) {
            try {
                const errorMessage = await response.json();

                if (errorMessage.message) {
                    showSnackbar(`${errorMessage.message}`, 'error');
                }
            }
            catch (error) {
                showSnackbar('An error occured.', 'error');
            }
        }
        else {
            showSnackbar('The announcement edited successfully.', 'success');
            setShowEditNotificationDialog(false);
            setSelectedNotification(null);
            await handleFetchNotifications();
        }

    };

    return (
        <>

            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Announcements</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>

            <Paper style={{ margin: 18 }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {selectedNotifications.length > 0 ? (
                        <Button
                            color="error"
                            onClick={() => setShowDeleteNotificationsDialog(true)}
                            startIcon={<DeleteIcon />}
                        >
                            Delete Announcement
                        </Button>

                    ) : (

                        <Button
                            onClick={() => setShowCreateNotificationDialog(true)}
                            startIcon={<AddIcon />}
                        >
                            New Announcement
                        </Button>
                    )}
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th">Choose</TableCell>
                                <TableCell component="th">Date/Time</TableCell>
                                <TableCell component="th">Subject</TableCell>
                                <TableCell component="th">Body</TableCell>
                                <TableCell component="th">Link</TableCell>
                                <TableCell component="th">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map(notification => {
                                const isSelected = selectedNotifications.includes(notification.id);
                                return (
                                    <TableRow key={notification.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected}
                                                onClick={e => handleSelectNotification(e, notification.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{format((new Date(notification.dateTime)), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{notification.subject}</TableCell>
                                        <TableCell>{notification.body}</TableCell>
                                        <TableCell>
                                            {notification.linkUrl ? <Link href={notification.linkUrl}>{notification.linkText || notification.linkUrl}</Link> : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => showEditNotificationModal(notification)}>
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {isLoading && (
                    <LoadingOverlay />
                )}

                {showCreateNotificationDialog && (
                    <CreateNotificationDialog
                        onHide={() => setShowCreateNotificationDialog(false)}
                        onConfirm={handleCreateNotification}
                    />
                )}

                {showDeleteNotificationsDialog && (
                    <DeleteNotificationsDialog
                        notifications={selectedNotifications}
                        onHide={() => setShowDeleteNotificationsDialog(false)}
                        onConfirm={handleDeleteNotifications}
                    />
                )}

                {showEditNotificationDialog && (
                    <EditNotificationDialog
                        shown={showEditNotificationDialog}
                        onHide={() => setShowEditNotificationDialog(false)}
                        onConfirm={handleEditNotification}
                        onLoading={isLoading}
                        notification={selectedNotification}
                    />
                )}

            </Paper>

        </>
    );
};

export default Notifications;