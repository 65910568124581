import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useAccount from '../../hooks/useAccount';
import CircularProgress from '@mui/material/CircularProgress';

const CustomFonts = () => {
    const { customFonts } = useAccount();
    const [fontLoading, setIsFontLoading] = useState(false);

    useEffect(() => {
        if (customFonts && customFonts.length > 0) {
            setIsFontLoading(true);

            const fontLinks = customFonts
                .filter(element => element.url && element.url.length > 0)
                .map(element => {
                    if (element.url.length > 0) {
                        const link = document.createElement('link');
                        link.setAttribute('rel', 'stylesheet');
                        link.href = element.url;
                        return link;
                    }
                });

            if (fontLinks.length === 0) {
                setIsFontLoading(false);
                return;
            }

            let loadedFontsCount = 0;

            const handleFontLoad = () => {
                loadedFontsCount++;

                if (loadedFontsCount === fontLinks.length) {
                    setIsFontLoading(false);
                }
            };

            fontLinks.forEach(link => {
                link.onload = handleFontLoad;
                document.head.appendChild(link);
            });

            return () => {
                fontLinks.forEach(link => {
                    link.onload = null;
                });
            };
        }
        else {
            setIsFontLoading(false);
        }
    }, [customFonts]);

    if (fontLoading) {
        return <CircularProgress size={16} color="inherit" />;
    }

    return (
        <Box>
            {customFonts.length > 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {customFonts.map((font, index) => (
                            <Grid item xs={4} sm={4} md={3} key={index}>
                                <Card sx={{ overflow: 'hidden' }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {font.label}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="body1"
                                            component="div"
                                            sx={{ fontFamily: font.fontFamily }}>
                                            {font.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ fontFamily: font.fontFamily }}>
                                            The quick brown fox jumps over the lazy dog
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ) : (
                <Alert sx={{ mt: 2 }} severity="info">No Brand Colours available.</Alert>
            )}
        </Box>
    );
};

export default CustomFonts;