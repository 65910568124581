import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useApi from '../hooks/useApi';
import AppBar from '@mui/material/AppBar';
import useSnackbar from '../hooks/useSnackbar';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SurveyGraph from './surveyComponents/SurveyGraph';
import SurveyTable from './surveyComponents/SurveyTable';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import { Paper } from '@mui/material';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, height: 'calc(100vh - 64px)', overflow: 'auto' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

const SurveyResults = () => {
    const { id } = useParams();
    const history = useHistory();
    const { handleGet } = useApi();
    const [survey, setSurvey] = useState(null);
    const [surveyResults, setSurveyResults] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const { showSnackbar } = useSnackbar();
    const [noResult, setNoResult] = useState(false);

    const handleLoadSurvey = async () => {
        try {
            const response = await handleGet(`surveys/${id}`);

            if (!response.ok) {
                showSnackbar('Error loading survey', 'error');
                history.push('/editor');
                return;
            }

            const data = await response.json();
            const jsonData = JSON.parse(data.json);

            if (!jsonData.pages) {
                setNoResult(true);
                setIsLoading(false);
                return;
            }

            const elements = jsonData.pages.flatMap(page => page.elements);
            setSurvey({ elements });
        }
        catch (error) {
            showSnackbar('Error loading survey', 'error');
            history.push('/editor');
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleLoadResults = async () => {
        const response = await handleGet(`surveys/${id}/results`);

        if (!response.ok) {
            showSnackbar('Error loading results', 'error');
            history.push('/editor');
            return;
        }

        const data = await response.json();

        if (data.length > 0) {
            const parsedResults = data.map(item => JSON.parse(item.result));

            setSurveyResults(parsedResults);
        }
        else {
            setSurveyResults(data ?? []);
        }

        setIsLoading(false);
    };

    const handleLoad = async () => {
        await handleLoadSurvey();
        await handleLoadResults();
        setIsLoading(false);
    };

    const handleBack = () => {
        history.push({
            pathname: '/editor',
            state: { type: 'survey' }
        });
    };

    useEffect(() => {
        handleLoad();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64, display: 'flex', flexDirection: 'row' }}
            >
                <Button onClick={handleBack} sx={{ marginLeft: 2 }} variant="text" startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                <Box>
                    <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                        <Tab icon={<BarChartIcon />} iconPosition="start" label="Graph View" />
                        <Tab icon={<TableViewIcon />} iconPosition="start" label="Table View" />
                    </Tabs>
                </Box>
            </AppBar>
            {noResult && (
                <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', textAlign: 'center' }}>
                    This Survey has no results yet.
                </Paper>
            )}
            {!noResult && (
                <Box>
                    <CustomTabPanel value={tab} index={0}>
                        <SurveyGraph survey={survey} surveyResults={surveyResults} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tab} index={1}>
                        <SurveyTable surveyJson={survey} surveyResults={surveyResults} />
                    </CustomTabPanel>
                </Box>
            )}
        </>
    );
};

export default SurveyResults;