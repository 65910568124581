import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import CenteredProgress from '../../loadingOverlay/CenteredProgress';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';

const rowsPerPageOptions = [10, 20, 50];

const LinksTable = ({ clicks }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell component="th">
                            URL
                        </TableCell>
                        <TableCell component="th">
                            Clicks
                        </TableCell>
                        <TableCell component="th">
                            1st Click
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clicks.map((click, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {click.url}
                            </TableCell>
                            <TableCell>
                                {click.count}
                            </TableCell>
                            <TableCell>
                                {new Date(click.dateTime).toLocaleString('en-GB')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const OpenTimesTable = ({ opens }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    {opens.map((open, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {new Date(open.dateTime).toLocaleString('en-GB')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const DeliveryDetailsTable = ({ campaignSubscriber }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200, verticalAlign: 'top' }}>
                            Delivery Status:
                        </TableCell>
                        <TableCell>
                            {campaignSubscriber.deliveryStatus}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200, verticalAlign: 'top' }}>
                            Bounce Category:
                        </TableCell>
                        <TableCell>
                            {campaignSubscriber.bounceCategory}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200, verticalAlign: 'top' }}>
                            Bounce Message:
                        </TableCell>
                        <TableCell>
                            {campaignSubscriber.bounceMessage}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SendDetailsTable = ({ subscriber, campaignSubscriber }) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200 }}>
                            Send Time:
                        </TableCell>
                        <TableCell>
                            {subscriber.dateTimeSent ? new Date(subscriber.dateTimeSent).toLocaleString('en-GB') : ''}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200 }}>
                            Number of Opens:
                        </TableCell>
                        <TableCell>
                            {subscriber.opens}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200 }}>
                            Subscription Status:
                        </TableCell>
                        <TableCell>
                            {subscriber.hasUnsubscribed ? 'Unsubscribed' : 'Subscribed'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" sx={{ minWidth: 200 }}>
                            Send Status:
                        </TableCell>
                        <TableCell>
                            {campaignSubscriber.sendStatus}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const SendHistoryTable = ({ subscriber, campaignId }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [subscriberCampaigns, setSubscriberCampaigns] = useState(null);
    const [subscriberCampaignsCount, setSubscriberCampaignsCount] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const { handleGet } = useApi();

    const skip = page * rowsPerPage;
    const take = rowsPerPage;

    const fetchSubscriberCampaigns = async () => {
        const url = buildUrl(`reports/${campaignId}/subscribers/${subscriber.id}/campaigns`, ({ skip, take }));
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setSubscriberCampaigns(await response.json());
    };

    const fetchSubscriberCampaignsCount = async () => {
        const url = buildUrl(`reports/${campaignId}/subscribers/${subscriber.id}/campaigns/count`);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        setSubscriberCampaignsCount(await response.json());
    };

    const handleFetches = () => {
        fetchSubscriberCampaigns();
        fetchSubscriberCampaignsCount();
    };

    useEffect(() => {
        handleFetches();
    }, [subscriber, rowsPerPage, page]);

    useEffect(() => {
        (subscriberCampaigns && subscriberCampaignsCount) && setIsLoading(false);
    }, [subscriberCampaigns, subscriberCampaignsCount]);

    if (isLoading) {
        return <CenteredProgress />;
    }

    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">
                                Campaign
                            </TableCell>
                            <TableCell component="th" />
                            <TableCell component="th">
                                Sent time
                            </TableCell>
                            <TableCell component="th">
                                Status
                            </TableCell>
                            <TableCell component="th" align="right">
                                Total Opens
                            </TableCell>
                            <TableCell component="th" align="right">
                                Total Clicks
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriberCampaigns.map(campaign => (
                            <TableRow key={campaign.id}>
                                <TableCell>
                                    {campaign.name}
                                </TableCell>
                                <TableCell>
                                    <Button disabled={campaign.id === campaignId} variant="text" onClick={() => history.push(`/reports/${campaign.id}`)}>View</Button>
                                </TableCell>
                                <TableCell>
                                    {new Date(campaign.sendDateTime).toLocaleString('en-GB')}
                                </TableCell>
                                <TableCell>
                                    {campaign.sendStatus}
                                </TableCell>
                                <TableCell align="right">
                                    {campaign.totalOpens}
                                </TableCell>
                                <TableCell align="right">
                                    {campaign.totalClicks}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={subscriberCampaignsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
            />
        </>
    );
};

const SubscriberDialog = ({ open, onClose, subscriber, campaignId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const [campaignSubscriber, setCampaignSubscriber] = useState(null);
    const { handleGet } = useApi();

    const TabPanel = ({ children, index }) => ((tab === index) ? <Paper sx={{ paddingTop: 2 }}>{children}</Paper> : null);

    const fetchCampaignSubscriber = async () => {
        const url = buildUrl(`reports/${campaignId}/subscribers/${subscriber.id}`);
        const response = await handleGet(url);
        setCampaignSubscriber(await response.json());
    };

    const handleFetches = () => {
        fetchCampaignSubscriber();
    };

    useEffect(() => {
        handleFetches();
    }, []);

    useEffect(() => {
        campaignSubscriber && setIsLoading(false);
    }, [campaignSubscriber]);

    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
            <DialogTitle>Report for contact: {subscriber.emailAddress}</DialogTitle>
            {isLoading ? (
                <CenteredProgress />
            ) : (
                <DialogContent>
                    <AppBar
                        position="relative"
                        color="inherit"
                    >
                        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                            <Tab label="Send details" />
                            <Tab label="Delivery details" disabled={!subscriber.bounced} />
                            <Tab label="Open times" disabled={!subscriber.opened} />
                            <Tab label="Links clicked" disabled={!subscriber.clicked} />
                            <Tab label="Send history" />
                        </Tabs>
                    </AppBar>
                    <TabPanel index={0}>
                        <SendDetailsTable subscriber={subscriber} campaignSubscriber={campaignSubscriber} />
                    </TabPanel>
                    <TabPanel index={1}>
                        <DeliveryDetailsTable campaignSubscriber={campaignSubscriber} />
                    </TabPanel>
                    <TabPanel index={2}>
                        <OpenTimesTable opens={campaignSubscriber.opens} />
                    </TabPanel>
                    <TabPanel index={3}>
                        <LinksTable clicks={campaignSubscriber.clicks} />
                    </TabPanel>
                    <TabPanel index={4}>
                        <SendHistoryTable subscriber={subscriber} campaignId={campaignId} />
                    </TabPanel>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubscriberDialog;