import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AccountSettings from './accountSettings/AccountSettings';
import Products from './products/Products';
import SmtpSettings from './smtpSettings/SmtpSettings';
import Invoices from './invoices/Invoices';
import Auditing from './auditing/Auditing';
import ApiSettings from './apiSettings/ApiSettings';
import AccountNavigation from './AccountNavigation';
import { drawerWidth } from '../theme/constants';
import Box from '@mui/material/Box';
import Users from './users/Users';
import EmailAuthentication from './emailAuthentication/EmailAuthentication';

const Account = () => {
    const { path } = useRouteMatch();

    return (
        <>
            <AccountNavigation />
            <Box sx={{ marginLeft: `${drawerWidth}px`, flexGrow: 1 }}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <AccountSettings />
                    </Route>
                    <Route exact path={`${path}/auditing`}>
                        <Auditing />
                    </Route>
                    <Route exact path={`${path}/products`}>
                        <Products />
                    </Route>
                    <Route exact path={`${path}/invoices`}>
                        <Invoices />
                    </Route>
                    <Route exact path={`${path}/api`}>
                        <ApiSettings />
                    </Route>
                    <Route exact path={`${path}/smtp`}>
                        <SmtpSettings />
                    </Route>
                    <Route exact path={`${path}/users`}>
                        <Users />
                    </Route>
                    <Route exact path={`${path}/emailauth`}>
                        <EmailAuthentication />
                    </Route>
                </Switch>
            </Box>
        </>
    );
};

export default Account;