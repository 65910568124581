import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import useApi from '../hooks/useApi';
import buildUrl from '../buildUrl';
import Dashboard from './views/Dashboard';
import Automation from './views/Automation';
import AutomationEmailEditor from './views/AutomationEmailEditor';
import UploadHtml from '../editor/UploadHtml';
import UploadImages from '../editor/UploadImages';
import NewAutomationEmail from './views/NewAutomationEmail';
import CreateOrEditAutomationDialog from './dialogs/CreateOrEditAutomationDialog';
import TogglePublishDialog from './dialogs/TogglePublishDialog';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';

const dialogs = {
    CREATE_AUTOMATION: 1,
    EDIT_AUTOMATION: 2,
    TOGGLE_PUBLISH_AUTOMATION: 3,
    DELETE_AUTOMATION: 4
};

const Automations = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();

    const [isInitialising, setIsInitialising] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [allContactsCount, setAllContactsCount] = useState(null);
    const [dialog, setDialog] = useState(null);
    const [automationToEdit, setAutomationToEdit] = useState(null);
    const [automationToPublish, setAutomationToPublish] = useState(null);
    const [automationToDelete, setAutomationToDelete] = useState(null);
    const [allAutomations, setAllAutomations] = useState([]);
    const [availableTriggers, setAvailableTriggers] = useState([]);
    const [groups, setGroups] = useState([]);

    const handleFetchAutomations = async () => {
        const response = await handleGet('automations');

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const automations = await response.json();

        setAllAutomations(automations);
        setIsLoading(false);
    };

    const handleCreateAutomation = async automation => {
        setIsLoading(true);

        const response = await handlePost('automations', automation);

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        const newAutomation = await response.json();

        await handleFetchAutomations();
        history.push(`automations/${newAutomation.id}`);
        setIsLoading(false);
    };

    const handleDeleteAutomation = async id => {
        setIsLoading(true);
        const response = await handleDelete(`automations/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        history.push('/automations');
        await handleFetchAutomations();
        setIsLoading(false);
    };

    const handleUpdateAutomation = async automation => {
        setIsLoading(true);

        const response = await handlePut(`automations/${automation.id}`, automation);

        if (!response.ok) {
            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        await handleFetchAutomations();

        setIsLoading(false);
    };

    const handleFetchTriggers = async () => {
        const response = await handleGet('automations/triggers');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const availableTriggers = await response.json();
        setAvailableTriggers(availableTriggers);
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const groups = (await response.json());
        setGroups(groups);
    };

    const handleFetchContactsCount = async () => {
        const params = {
            unsubscribed: false,
            bounced: false
        };

        const url = buildUrl('contacts/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setAllContactsCount(count);
    };

    const handleInit = async () => {
        await handleFetchTriggers();
        await handleFetchAutomations();
        await handleFetchGroups();
        await handleFetchContactsCount();
        setIsInitialising(false);
    };

    // dialogs

    useEffect(() => {
        setDialog(automationToEdit ? dialogs.EDIT_AUTOMATION : null);
    }, [automationToEdit]);

    useEffect(() => {
        setDialog(automationToDelete ? dialogs.DELETE_AUTOMATION : null);
    }, [automationToDelete]);

    useEffect(() => {
        setDialog(automationToPublish ? dialogs.TOGGLE_PUBLISH_AUTOMATION : null);
    }, [automationToPublish]);

    useEffect(() => {
        handleInit();
    }, []);

    return (
        <>
            <Switch>
                <Route exact path={`${path}`}>
                    <Dashboard
                        allAutomations={allAutomations}
                        groups={groups}
                        availableTriggers={availableTriggers}
                        isInitialising={isInitialising}
                        isLoading={isLoading}
                        onCreateAutomation={() => setDialog(dialogs.CREATE_AUTOMATION)}
                        onSetAutomationToEdit={setAutomationToEdit}
                        onSetAutomationToDelete={setAutomationToDelete}
                        onSetAutomationToPublish={setAutomationToPublish}
                    />
                </Route>
                <Route exact path={`${path}/:automationId`}>
                    {isLoading && (
                        <LoadingOverlay />
                    )}
                    <Automation
                        automations={allAutomations}
                        availableTriggers={availableTriggers}
                        onOpenEditAutomationDialog={setAutomationToEdit}
                        onOpenDeleteAutomationDialog={setAutomationToDelete}
                        onOpenConfirmPublishOrDraftAutomationDialog={setAutomationToPublish}
                        onFetchAutomations={handleFetchAutomations}
                    />
                </Route>
                <Route exact path={`${path}/:automationId/emails/new`}>
                    <NewAutomationEmail />
                </Route>
                <Route exact path={`${path}/:automationId/emails/:emailId/upload`}>
                    <UploadHtml />
                </Route>
                <Route exact path={`${path}/:automationId/emails/:emailId/upload-images`}>
                    <UploadImages />
                </Route>
                <Route exact path={`${path}/:automationId/emails/:emailId`}>
                    <AutomationEmailEditor />
                </Route>
            </Switch>
            {dialog === dialogs.CREATE_AUTOMATION && (
                <CreateOrEditAutomationDialog
                    onClose={() => setDialog(null)}
                    onSubmit={handleCreateAutomation}
                    isLoading={isLoading}
                    availableTriggers={availableTriggers}
                    allContactsCount={allContactsCount}
                />
            )}
            {(automationToEdit && dialog === dialogs.EDIT_AUTOMATION) && (
                <CreateOrEditAutomationDialog
                    onClose={() => setAutomationToEdit(null)}
                    onSubmit={handleUpdateAutomation}
                    isLoading={isLoading}
                    availableTriggers={availableTriggers}
                    allContactsCount={allContactsCount}
                    automation={automationToEdit}
                />
            )}
            {(automationToPublish && dialog === dialogs.TOGGLE_PUBLISH_AUTOMATION) && (
                <TogglePublishDialog
                    automation={automationToPublish}
                    onClose={() => setAutomationToPublish(null)}
                    onSubmit={handleUpdateAutomation}
                    availableTriggers={availableTriggers}
                />
            )}
            {(automationToDelete && dialog === dialogs.DELETE_AUTOMATION) && (
                <ConfirmDeleteDialog
                    onClose={() => setAutomationToDelete(null)}
                    onConfirm={handleDeleteAutomation}
                    item={automationToDelete}
                    type="Automation"
                />
            )}
        </>
    );
};

export default Automations;