import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { colours } from '../theme/constants.js';
import { useLocation, Link } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import { useAuth0 } from '@auth0/auth0-react';
import useAccount from '../hooks/useAccount';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotifications from '../hooks/useNotifications';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LinkButton from '@mui/material/Link';
import LoginIcon from '@mui/icons-material/Login';
import ListSubheader from '@mui/material/ListSubheader';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import SuperAdminIcon from '../svgIcons/SuperAdminIcon';
import HelpIcon from '@mui/icons-material/Help';
import EditProfileDialog from './dialogs/EditProfileDialog.js';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import ToggleUserFeatureModal from './dialogs/ToggleUserFeatureModal.js';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SupportIcon from '@mui/icons-material/Support';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// todo move to config
// todo this should be dynamic so if people logged in using their microsoft account their session is cleared correctly
const returnTo = 'https://www.newzapp.co.uk/email-marketing/logout-thank-you.php';

const iconButton = {
    color: '#FFFFFF',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: colours.secondary
    }
};

const HelpDropdownMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                sx={iconButton}
                size="large"
                onClick={handleClick}
            >
                <HelpIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <ListSubheader>Get Support</ListSubheader>
                <MenuItem component="a" href="tel:01392447200">
                    <ListItemIcon>
                        <PhoneIcon fontSize="small" />
                    </ListItemIcon>
                    01392 447 200
                </MenuItem>
                <MenuItem component="a" href="mailto:support@newzapp.com">
                    <ListItemIcon>
                        <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    support@newzapp.com
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/raise-a-ticket" target="_blank">
                    <ListItemIcon>
                        <SupportIcon fontSize="small" />
                    </ListItemIcon>
                    Raise A Ticket
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/" target="_blank">
                    <ListItemIcon>
                        <LanguageIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Knowledge Base
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/training-videos" target="_blank">
                    <ListItemIcon>
                        <SubscriptionsIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Training Videos
                </MenuItem>
                <MenuItem component="a" href="https://newzapp.co.uk/why-newzapp/releases-bug-fixes-and-improvements/" target="_blank">
                    <ListItemIcon>
                        <RocketLaunchIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Releases & Bug Fixes
                </MenuItem>
            </Menu>
        </>
    );
};

const UserDropdownMenu = ({ handleFeatureDialog }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
    const open = Boolean(anchorEl);
    const { user, logout } = useAuth0();
    const { customerId, name, emailAddress, avatarURL, accounts, handleChangeAccount, userPermissions, handleRefresh, user: currentUser } = useAccount();
    const isSuperAdmin = userPermissions.includes('manage:accounts', 'manage:campaigns', 'manage:templates', 'manage:notifications');

    const currentAccount = accounts.find(account => account.id === customerId);
    const availableAccounts = accounts.filter(account => account.id !== currentAccount.id);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleSwitchAccount = async id => {
        if (id === customerId) {
            return;
        }

        // todo loading overlay

        await handleChangeAccount(id);
    };

    const displayName = name ? name : user.name;

    const UserAvatar = props => {
        return (
            <Avatar
                {...props}
                src={avatarURL}
            >
                {displayName.substring(0, 1)}
            </Avatar>
        );
    };

    return (
        <>
            <IconButton
                edge="end"
                sx={{ ...iconButton, p: 1 }}
                size="large"
                onClick={handleClick}
            >
                <UserAvatar sx={{ width: 32, height: 32 }} />
            </IconButton>
            {/* <IconButton
                color="primary"
                edge="end"
                sx={iconButton}
                size="large"
                onClick={handleClick}
            >
                <AccountCircleIcon />
            </IconButton> */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            // PaperProps={{
            //     sx: {
            //         overflow: 'visible',
            //         '&:before': {
            //             content: '""',
            //             display: 'block',
            //             position: 'absolute',
            //             top: 0,
            //             right: 22,
            //             width: 10,
            //             height: 10,
            //             bgcolor: 'background.paper',
            //             transform: 'translateY(-50%) rotate(45deg)',
            //             zIndex: 0,
            //         }
            //     },
            // }}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            src={avatarURL}
                            sx={{ width: 64, height: 64, mr: 2 }}
                        >
                            {displayName.substring(0, 1)}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={displayName}
                        secondary={(
                            <>
                                {emailAddress}
                                <LinkButton component="button" variant="body2" underline="none" fontWeight="bold" display="block" onClick={() => setShowEditProfileDialog(true)}>
                                    Edit profile
                                </LinkButton>
                            </>
                        )}
                        sx={{ whiteSpace: 'nowrap' }}
                    />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListSubheader>Account Details</ListSubheader>
                <ListItem sx={{ py: 0 }}>
                    <ListItemText
                        primary={currentAccount.name}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }
                        }}
                        secondary={(
                            <>
                                {`Customer ID: ${currentAccount.id}`}
                                <Box>Type: {currentAccount.isInternalCommunications ? 'Internal' : 'External'}</Box>
                            </>
                        )}
                    />
                </ListItem>
                {availableAccounts.length > 0 && (
                    <div>
                        <Divider sx={{ my: 1 }} />
                        <ListSubheader>Switch Account</ListSubheader>
                        {availableAccounts.map(account => (
                            <MenuItem
                                key={account.id}
                                onClick={() => handleSwitchAccount(account.id)}
                            >
                                <ListItemIcon>
                                    <LoginIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={account.name}
                                    primaryTypographyProps={{
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                    secondary={(
                                        <>
                                            {`Customer ID: ${account.id}`}
                                            <Box>Type: {account.isInternalCommunications ? 'Internal' : 'External'}</Box>
                                        </>
                                    )}
                                />
                            </MenuItem>
                        ))}
                    </div>
                )}
                <Divider sx={{ my: 1 }} />
                <MenuItem component={Link} to="/account" onClick={handleClose}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Account Settings" />
                </MenuItem>
                <Divider sx={{ my: 1 }} />
                {isSuperAdmin && (
                    <Box>
                        <MenuItem component={Link} to="/superadmin" onClick={handleClose}>
                            <ListItemIcon>
                                <SuperAdminIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Super Admin" />
                        </MenuItem>
                        <Divider sx={{ my: 1 }} />
                    </Box>
                )}
                {(currentUser.userFeature.length > 0 || currentUser.feature.some(feature => feature.level === 1)) && (
                    <Box>
                        <MenuItem onClick={handleFeatureDialog}>
                            <ListItemIcon>
                                <ToggleOnRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Feature Preview
                        </MenuItem>
                        <Divider sx={{ my: 1 }} />
                    </Box>
                )}
                <MenuItem onClick={() => logout({ returnTo })}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Sign Out
                </MenuItem>
            </Menu>
            {showEditProfileDialog && (
                <EditProfileDialog
                    displayName={name || displayName}
                    avatarURL={avatarURL}
                    onClose={() => setShowEditProfileDialog(false)}
                    onSubmit={() => handleRefresh()}
                />
            )}
        </>
    );
};

const Header = ({ onToggleNotifications }) => {
    const theme = useTheme();
    const location = useLocation();
    const { unreadNotificationsCount } = useNotifications();
    const [showFeatureDialog, setShowFeatureDialog] = useState(false);

    const isActive = href => {
        if (href === '/') {
            return location.pathname === href;
        }

        return location.pathname.startsWith(href);
    };

    const button = {
        fontWeight: 100,
        fontSize: '1.15em',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#FFFFFF',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: colours.secondary
        },
        '&:focus': {
            color: '#FFFFFF'
        }
    };

    const activeButton = {
        color: '#FFFFFF',
        backgroundColor: colours.secondary
    };

    const getStyles = path => {
        const active = isActive(path);

        if (active) {
            return {
                ...button,
                ...activeButton
            };
        }

        return button;
    };

    return (
        <>
            <AppBar color="primary" sx={{ zIndex: theme.zIndex.drawer + 2, displayPrint: 'none' }}>
                <Toolbar>
                    <img src="/images/newzapp-motif.svg" style={{ maxHeight: 20, marginRight: 16 }} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Button component={Link} variant="text" color="primary" to="/" sx={getStyles('/')}>Start</Button>
                        <Button component={Link} variant="text" color="primary" to="/editor" sx={getStyles('/editor')}>Create &amp; Send</Button>
                        <Button component={Link} variant="text" color="primary" to="/contacts" sx={getStyles('/contacts')}>Contacts</Button>
                        <Button component={Link} variant="text" color="primary" to="/automations" sx={getStyles('/automations')}>Automations</Button>
                        <Button component={Link} variant="text" color="primary" to="/reports" sx={getStyles('/reports')}>Reports</Button>
                    </Box>

                    <IconButton
                        color="primary"
                        onClick={onToggleNotifications}
                        sx={iconButton}
                        size="large"
                    >
                        <Badge
                            color="error"
                            badgeContent={unreadNotificationsCount}
                            max={99}
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <HelpDropdownMenu />

                    <UserDropdownMenu handleFeatureDialog={() => setShowFeatureDialog(true)} />

                </Toolbar>
            </AppBar>
            <Toolbar />

            {showFeatureDialog && (
                <ToggleUserFeatureModal
                    shown={showFeatureDialog}
                    onHide={() => setShowFeatureDialog(false)}
                />
            )}
        </>
    );
};

export default Header;