import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useApi from '../../hooks/useApi';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useSnackbar from '../../hooks/useSnackbar';
import Divider from '@mui/material/Divider';

import Stepper from './components/Stepper';
import Domain from './components/Domain';
import Instruction from './components/Instruction';
import { Button } from '@mui/material';

const EmailAuthentication = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const { handleGet } = useApi();
    const [account, setAccount] = useState(null);
    const [boolStepper, setBoolStepper] = useState([]);
    const { showSnackbar } = useSnackbar();

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        const steps = ['mxCheck', 'spf', 'dkim', 'dmarc'];
        const dateStatusArray = steps.map(field => !!data[field]);

        setBoolStepper(dateStatusArray);

        setAccount(data);
    };

    const handleSubmit = async () => {
        setVerifyLoading(true);

        const response = await handleGet(`account/${account.id}/email-authentication`);

        const data = await response.json();

        let count = 0;

        for (let key in data) {
            if (data[key] === true) {
                count += 1;
            }
        }

        if (!response.ok) {
            showSnackbar('Checks not completed. Please contact support', 'error');
            setIsLoading(false);
            setVerifyLoading(false);
            return;
        }

        if (count === 4) {
            showSnackbar('All checks completed', 'success');
            setVerifyLoading(false);
        }
        else {
            showSnackbar(`${count} out of 4 checks completed`, 'warning');
            setVerifyLoading(false);
        }

        await handleFetchAccount();
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        account && setIsLoading(false);
    }, [account]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64 }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">Email Authentication</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2 }}>
                <Paper>
                    <Stepper data={boolStepper} />
                </Paper>
                <Paper sx={{ marginTop: 2 }}>
                    <Domain account={account} onRefresh={handleFetchAccount} />
                </Paper>
                <Paper sx={{ marginTop: 2 }}>
                    <Instruction account={account} />
                    <Divider />
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button onClick={handleSubmit}>Verify</Button>
                </Box>
            </Box>
            {verifyLoading && <LoadingOverlay />}
        </>
    );
};

export default EmailAuthentication;