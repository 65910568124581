import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import HistoryIcon from '@mui/icons-material/History';
import ViewListIcon from '@mui/icons-material/ViewList';
import DescriptionIcon from '@mui/icons-material/Description';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { Link, useLocation } from 'react-router-dom';
import NavigationContainer from '../navigation/NavigationContainer';
import useAccount from '../hooks/useAccount';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const AccountNavigationListItem = ({ text, url, icon }) => {
    const location = useLocation();

    return (
        <ListItem button component={Link} to={url} selected={location.pathname === url}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} />
        </ListItem>
    );
};

const AccountNavigation = () => {
    const { hasCustomSmtpConfiguration, userLimit, userPermissions } = useAccount();
    const canManageUsers = userPermissions.includes('Manage Users');

    return (
        <NavigationContainer>
            <List>
                <AccountNavigationListItem text="Settings" url="/account" icon={<TuneIcon />} />
                <AccountNavigationListItem text="Audit Trail" url="/account/auditing" icon={<HistoryIcon />} />
                <AccountNavigationListItem text="Products" url="/account/products" icon={<ViewListIcon />} />
                <AccountNavigationListItem text="Invoices" url="/account/invoices" icon={<DescriptionIcon />} />
                <AccountNavigationListItem text="API Integration" url="/account/api" icon={<VpnKeyIcon />} />
                {hasCustomSmtpConfiguration && <AccountNavigationListItem text="SMTP Settings" url="/account/smtp" icon={<AllInboxIcon />} />}
                {(userLimit > 1 && canManageUsers) && <AccountNavigationListItem text="Manage Users" url="/account/users" icon={<PeopleAltIcon />} />}
                <AccountNavigationListItem text="Email Authentication" url="/account/emailauth" icon={<MarkEmailReadIcon />} />
            </List>
        </NavigationContainer>
    );
};

export default AccountNavigation;