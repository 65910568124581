import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import NotificationsContext from './NotificationsContext';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';
import config from '../config';

const { AUTH0_AUDIENCE: audience, AUTH0_SCOPE: scope } = config;

const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [connection, setConnection] = useState(null);
    const { getAccessTokenSilently } = useAuth0();
    const { showSnackbar } = useSnackbar();
    const { handleGet, handlePut } = useApi();
    const { handleRefresh } = useAccount();

    const handleFetchNotifications = async () => {
        const response = await handleGet('notifications');

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setNotifications(data.notifications);
        setUnreadNotificationsCount(data.unreadNotificationsCount);
    };

    const handleMarkAsRead = async notification => {
        const copyOfNotifications = [...notifications];
        const i = copyOfNotifications.findIndex(n => n.id === notification.id);
        copyOfNotifications[i].isRead = true;

        // todo check notification is not null

        setNotifications(copyOfNotifications);
        setUnreadNotificationsCount(count => count - 1);

        await handlePut(`notifications/${notification.id}`, { isRead: true });
        await handleFetchNotifications();
    };

    const handleSetUpSignalR = () => {
        const connection = new HubConnectionBuilder()
            .withUrl('/hubs/notifications', {
                accessTokenFactory: () => getAccessTokenSilently({
                    audience,
                    scope
                })
            })
            .withAutomaticReconnect()
            .build();

        setConnection(connection);
    };

    const handleStartSignalR = async () => {
        try {
            await connection.start();

            connection.on('ReceiveNotification', notification => {
                if (notification.type === 'system') {
                    const body = JSON.parse(notification.body);

                    if (body.type === 'permissionsUpdated' || body.type === 'userRevoked') {
                        handleRefresh();
                    }
                }

                showSnackbar(notification.subject);
                setNotifications(notifications => [notification, ...notifications]);
                setUnreadNotificationsCount(count => count + 1);
            });

            // todo cleanup
        }
        catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        handleFetchNotifications();
        handleSetUpSignalR();
    }, []);

    useEffect(() => {
        if (connection && connection.state !== HubConnectionState.Connected) {
            handleStartSignalR();
        }
    }, [connection]);

    return (
        <NotificationsContext.Provider value={{ notifications, unreadNotificationsCount, handleMarkAsRead }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;
