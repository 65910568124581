import React from 'react';
import TableCell from '@mui/material/TableCell';
import { format } from 'date-fns';
import formatFileSize from '../formatFileSize';

const renderValue = (dataType, value) => {
    if (dataType === 'boolean') return value ? 'Yes' : 'No';
    if (dataType === 'number') return value ?? 0;
    if (dataType === 'filesize') return value ? formatFileSize(value) : '0 B';
    if (!value) return '-';
    if (dataType === 'dateTime') return format((new Date(value)), 'dd/MM/yyyy HH:mm');
    return value;
};

const DataColumn = ({ value, dataType, align, nowrap = false }) => {
    const renderedValue = renderValue(dataType, value);

    return (
        <TableCell align={align} sx={nowrap ? { whiteSpace: 'nowrap' } : null}>
            {renderedValue}
        </TableCell>
    );
};

export default DataColumn;