import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import isValid from 'date-fns/isValid';
import enLocale from 'date-fns/locale/en-GB';

const DatePicker = ({ value, onChange, label, fullsize = false, margin = 'none' }) => {
    const renderInput = params => {
        if (fullsize) {
            return (
                <TextField
                    {...params}
                    fullWidth
                />
            );
        }
        else {
            return (
                <TextField
                    {...params}
                    size="small"
                    margin={margin}
                    sx={{
                        mr: 1,
                        width: 200
                    }}
                />
            );
        }
    };

    const handleChangeDate = date => {
        if (!date) {
            onChange(null);
        }
        else if (isValid(date)) {
            onChange(date);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <MuiDatePicker
                mask="__/__/____"
                inputFormat="dd/MM/yyyy"
                disableFuture
                value={value}
                onChange={handleChangeDate}
                views={['day']}
                clearable={true}
                clearText="Clear"
                label={label}
                renderInput={params => renderInput(params)}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;