import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const nameRegex = /[a-zA-Z0-9\s-]/g;

const EditSegmentDialog = ({ onClose, onSubmit, segment }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();

        await onSubmit({
            name,
            groupId: segment.groupId,
            id: segment.id,
            filters: segment.filters,
            ...(description && { description })
        });

        onClose();
    };

    useEffect(() => {
        segment && setName(segment.name);
        segment?.description && setDescription(segment.description);
    }, [segment]);

    useEffect(() => {
        setIsValid(name.trim() !== '' && (name.match(nameRegex)?.length === name.length));
    }, [name]);

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Edit Segment</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            autoFocus
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            value={name}
                            required
                            onChange={e => setName(e.target.value)}
                            margin="none"
                            error={!isValid}
                            helperText="Name must be alphanumeric. Hyphens and spaces are allowed."
                        />
                        <TextField
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            margin="none"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" disabled={!isValid}>Save Changes</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditSegmentDialog;