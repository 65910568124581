import React, { useEffect, useState } from 'react';
import { format, endOfDay } from 'date-fns';
import Box from '@mui/material/Box';
import useSearch from '../hooks/useSearch';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import DatePicker from '../DatePicker';
import TablePagination from '@mui/material/TablePagination';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import buildUrl from '../buildUrl';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import TableBody from '@mui/material/TableBody';
import { CircularProgress } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PollIcon from '@mui/icons-material/Poll';
import Tooltip from '@mui/material/Tooltip';

const rowsPerPageOptions = [10, 25, 50];

const iconStyle = {
    marginRight: 2,
    verticalAlign: 'middle'
};

const SurveyNameStatus = ({ name, status }) => {
    const StatusIcon = () => {
        if (status === 'Published') {
            return <PublishedWithChangesIcon fontSize="small" color="success" sx={iconStyle} />;
        }

        if (status === 'Unpublished') {
            return <UnpublishedIcon fontSize="small" color="info" sx={iconStyle} />;
        }

        return <PollIcon fontSize="small" color="disabled" sx={iconStyle} />;
    };

    return (
        <>
            <Tooltip title={status} arrow>
                <StatusIcon />
            </Tooltip>
            {name}
        </>
    );
};

const ToolbarItem = ({ children }) => {
    return <Box sx={{ display: 'inline-flex', margin: 0.5, marginRight: 0 }}>{children}</Box>;
};

const EmptyTable = ({ isInitialising, filtersApplied }) => {
    if (isInitialising) {
        return null;
    }

    return (
        <Box sx={{ padding: 6, textAlign: 'center' }}>
            {filtersApplied ? (
                <Typography>No data</Typography>
            ) : (
                <Typography>You haven't created any Survey yet.</Typography>
            )}
        </Box>
    );
};

const SurveySelect = ({ type, onSetCampaign }) => {
    const [isInitialising, setIsInitialising] = useState(true);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('Modified');
    const [orderDesc, setOrderDesc] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const { handleGet } = useApi();
    const [status, setStatus] = useState('Active');

    const handleSelectSurvey = (e, survey) => {
        e.preventDefault();
        onSetCampaign(survey);
    };

    const handleFetchCount = async () => {
        const surveyParams = {
            type,
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') }),
            ...(status !== '' && { status })
        };

        const url = buildUrl('surveys/count', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching count', 'error');
            return;
        }

        const data = await response.json();

        setCount(data);
    };

    const handleFetchItems = async () => {
        const surveyParams = {
            type,
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(endOfDay(toDate), 'yyyy-MM-dd HH:mm:ss') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            ...(status !== '' && { status })
        };

        const url = buildUrl('surveys', surveyParams);
        const response = await handleGet(url);

        if (!response.ok) {
            showSnackbar('Error fetching items', 'error');
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setItems(data);
        setIsLoading(false);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    const handleInit = async () => {
        await handleFetchItems();
        await handleFetchCount();
        setIsInitialising(false);
    };

    useEffect(() => {
        handleFetchCount();
    }, [searchValue, fromDate, toDate, status]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, status]);

    useEffect(() => {
        setIsLoading(true);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, status]);

    useEffect(() => {
        handleInit();
    }, []);

    useEffect(() => {
        setStatus('');
        handleInit();
    }, [type]);

    useEffect(() => {
        isLoading && handleFetchItems();
    }, [isLoading]);

    if (isInitialising) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Toolbar disableGutters sx={{ mx: 1.5, paddingTop: 0.5, paddingBottom: 0.5 }}>
                <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <ToolbarItem>
                        <OutlinedInput
                            value={tempSearchValue}
                            onChange={e => setTempSearchValue(e.target.value)}
                            placeholder="Search..."
                            size="small"
                            sx={{ mr: 1 }}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setTempSearchValue('')}
                                        edge="end"
                                        disabled={!Boolean(tempSearchValue)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        />
                    </ToolbarItem>
                    <ToolbarItem>
                        <DatePicker value={fromDate} onChange={setFromDate} label="Modified from date" />
                    </ToolbarItem>
                    <ToolbarItem>
                        <DatePicker value={toDate} onChange={setToDate} label="Modified to date" />
                    </ToolbarItem>
                </Box>
            </Toolbar>
            <Divider />
            {items.length > 0 ? (
                <>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row" align="left" sx={{ width: '30%' }}>
                                        <TableSortLabel
                                            active={orderBy === 'Name'}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Name', false)}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <TableSortLabel>
                                            Created Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <TableSortLabel>
                                            Last Modified
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(e => {
                                    return (
                                        <TableRow
                                            key={e.id}
                                            sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } }}
                                            onClick={(ei) => handleSelectSurvey(ei, e)}
                                        >
                                            <TableCell align="left">
                                                {isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />

                                                ) : <SurveyNameStatus name={e.name} status={e.status} />
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {e.created ? format(new Date(e.created), 'dd/MM/yyyy') : '-'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {e.modified ? format(new Date(e.modified), 'dd/MM/yyyy') : '-'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                    />
                </>
            ) : (
                <EmptyTable
                    filtersApplied={searchValue || fromDate || toDate || status}
                />
            )}
            {isLoading && <LoadingOverlay />}
        </>
    );
};

export default SurveySelect;