import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useApi from '../../../hooks/useApi';

const PermissionsPicker = ({ selectedPermissions, onChange }) => {
    const [permissions, setPermissions] = useState([]);
    const { handleGet } = useApi();

    const handleGetPermissions = async () => {
        const response = await handleGet('permissions');
        const data = await response.json();
        setPermissions(data);
    };

    const handleTogglePermission = p => {
        if (selectedPermissions.find(({ id }) => id === p.id)) {
            onChange([...selectedPermissions.filter(({ id }) => id !== p.id)]);
        }
        else {
            onChange([...selectedPermissions, p]);
        }
    };

    useEffect(() => {
        handleGetPermissions();
    }, []);

    if (permissions.length === 0) {
        return null;
    }

    return (
        <List>
            {permissions.map(({ id, name, description }) => (
                <ListItem
                    key={id}
                    dense
                    disableGutters
                    disablePadding
                >
                    <ListItemButton onClick={() => handleTogglePermission({ id })}>
                        <ListItemIcon dense="true">
                            <Checkbox
                                edge="start"
                                disableRipple
                                checked={Boolean(selectedPermissions.find(p => p.id === id))}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={name}
                            secondary={description}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default PermissionsPicker;