import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import Chart from 'chart.js/auto';
import { colours } from '../theme/constants';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import buildUrl from '../buildUrl';
import useSearch from '../hooks/useSearch';
import DataColumn from '../dataTable/DataColumn';
import DatePicker from '../DatePicker';
import useApi from '../hooks/useApi';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const MonthlySendsChart = () => {
    const [months, setMonths] = useState();
    const canvas = useRef();
    const { handleGet } = useApi();

    const handleBuildChart = () => {

        const data = {
            labels: months.map(u => u.label),
            datasets: [{
                label: 'Emails Sent',
                data: months.map(u => u.total),
                backgroundColor: colours.secondary
            }]
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                }
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    }
                }
            }
        };

        new Chart(canvas.current, {
            type: 'bar',
            data,
            options
        });
    };

    const handleLoadMonthlySends = async () => {
        const response = await handleGet('reports/sends/monthly');
        const data = await response.json();
        setMonths(data);
    };

    useEffect(() => {
        handleLoadMonthlySends();
    }, []);

    useEffect(() => {
        months && handleBuildChart();
    }, [months]);

    return (
        <Box sx={{ padding: 3, height: 300 }}>
            <canvas ref={canvas} />
        </Box>
    );
};

const rowsPerPageOptions = [10];

const emailColumns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text' },
    { name: 'subject', label: 'Subject', orderDesc: false, dataType: 'text' },
    { name: 'status', label: 'Status', orderDesc: false, dataType: 'text' },
    { name: 'sendDateTime', label: 'Date/Time', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'selected', label: 'Selected', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'sent', label: 'Sent', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueOpens', label: 'Unique Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'uniqueClicks', label: 'Unique Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const pageColumns = [
    { name: 'name', label: 'Name', orderDesc: false, dataType: 'text' },
    { name: 'subject', label: 'Title', orderDesc: false, dataType: 'text' },
    { name: 'status', label: 'Status', orderDesc: false, dataType: 'text' },
    { name: 'sendDateTime', label: 'Published', align: 'right', orderDesc: false, dataType: 'dateTime' },
    { name: 'totalShareLinkOpens', label: 'Opens', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'totalShareLinkClicks', label: 'Clicks', align: 'right', orderDesc: true, dataType: 'number' },
    { name: 'numberOfLinks', label: 'Links', align: 'right', orderDesc: true, dataType: 'number' }
];

const Dashboard = () => {
    const history = useHistory();
    const [campaigns, setCampaigns] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [type, setType] = useState('email');
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState('sendDateTime');
    const [orderDesc, setOrderDesc] = useState(true);
    const [tempSearchValue, setTempSearchValue] = useSearch(setSearchValue);
    const { handleGet } = useApi();

    const handleFetchCampaignsCount = async () => {
        const params = {
            type,
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') })
        };

        const url = buildUrl('reports/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setCount(data);
    };

    const handleFetchCampaignsPage = async () => {
        const params = {
            type,
            search: searchValue,
            ...(fromDate && { fromDate: format(fromDate, 'yyyy-MM-dd') }),
            ...(toDate && { toDate: format(toDate, 'yyyy-MM-dd') }),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc
        };

        const url = buildUrl('reports', params);
        const response = await handleGet(url);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setCampaigns(data);
    };

    const handleSort = (field, defaultOrder) => {
        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderBy(field);
            setOrderDesc(defaultOrder);
        }
    };

    useEffect(() => {
        handleFetchCampaignsCount();
    }, [searchValue, fromDate, toDate, type]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, type]);

    useEffect(() => {
        handleFetchCampaignsPage();
    }, [searchValue, fromDate, toDate, orderBy, orderDesc, rowsPerPage, page, type]);

    const columns = type === 'email' ? emailColumns : pageColumns;

    return (
        <Box sx={{ overflow: 'hidden' }}>

            <Paper sx={{ margin: 2 }}>
                <Toolbar variant="dense" sx={{ px: 2 }}>
                    <Typography component="h2" variant="subtitle2">Send History</Typography>
                </Toolbar>
                <Divider />
                <MonthlySendsChart />
            </Paper>

            <Paper sx={{ margin: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={type} onChange={(e, value) => setType(value)}>
                        <Tab label="Campaigns" value="email" />
                        <Tab label="Landing Pages" value="page" />
                    </Tabs>
                </Box>
                <Toolbar disableGutters sx={{ mx: 1 }}>
                    <OutlinedInput
                        value={tempSearchValue}
                        onChange={e => setTempSearchValue(e.target.value)}
                        placeholder="Search..."
                        size="small"
                        sx={{ m: 1 }}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setTempSearchValue('')}
                                    edge="end"
                                    disabled={!Boolean(tempSearchValue)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    <DatePicker value={fromDate} onChange={setFromDate} label="From Date" />
                    <DatePicker value={toDate} onChange={setToDate} label="To Date" />
                </Toolbar>
                <Divider />

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map(col => (
                                    <TableCell
                                        key={col.name}
                                        component="th"
                                        scope="row"
                                        align={col.align}
                                    >
                                        <TableSortLabel
                                            active={orderBy === col.name}
                                            direction={orderDesc ? 'desc' : 'asc'}
                                            onClick={() => handleSort(col.name, col.orderDesc)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaigns.map(campaign => (
                                <TableRow
                                    key={campaign.id}
                                    hover={true}
                                    onClick={() => history.push(`/reports/${campaign.type === 'page' ? 'page/' : ''}${campaign.id}`)}
                                >
                                    {columns.map(col => (
                                        <DataColumn
                                            key={col.name}
                                            align={col.align}
                                            dataType={col.dataType}
                                            value={campaign[col.name]}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>

        </Box>
    );
};

export default Dashboard;