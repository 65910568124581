import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Email from './Email';
import Page from './Page';
import UploadHtml from './UploadHtml';
import UploadImages from './UploadImages';
import NewFromTemplate from './NewFromTemplate';
import QRCode from './QRCode';
import SurveyEditor from './SurveyEditor';
import NewSurveyFromTemplate from './NewSurveyFromTemplate';
import SurveyResults from './SurveyResults';
import SurveyPreview from './SurveyPreview';

const Editor = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/:type/new`}>
                <NewFromTemplate />
            </Route>
            <Route exact path={`${path}/:type/upload`}>
                <UploadHtml />
            </Route>
            <Route exact path={`${path}/:type/:id/upload-images`}>
                <UploadImages />
            </Route>
            <Route exact path={`${path}/email/:id`}>
                <Email />
            </Route>
            <Route exact path={`${path}/email/:id/send`}>
                <Email emailStep={1} />
            </Route>
            <Route exact path={`${path}/page/:id`}>
                <Page />
            </Route>
            <Route exact path={`${path}/qr/:id`}>
                <QRCode />
            </Route>
            <Route exact path={`${path}/surveys/new-survey`}>
                <NewSurveyFromTemplate />
            </Route>
            <Route exact path={`${path}/surveys/:id`}>
                <SurveyEditor />
            </Route>
            <Route exact path={`${path}/surveys/:id/results`}>
                <SurveyResults />
            </Route>
            <Route exact path={`${path}/surveys/:id/preview`}>
                <SurveyPreview />
            </Route>
        </Switch>
    );
};

export default Editor;