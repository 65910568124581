import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import EmailsAndPages from './tabs/EmailsAndPages';
import QRCodes from './tabs/QRCodes';
import Surveys from './tabs/Surveys';
import ContentNavigation from './ContentNavigation';

const Dashboard = () => {
    const location = useLocation();
    const [type, setType] = useState(location?.state?.type ?? 'email');

    return (
        <>
            <ContentNavigation
                type={type}
                onSetType={setType}
            />
            <Paper sx={{ px: 1.5 }} square>
                {(type === 'email' || type === 'page') && <EmailsAndPages type={type} />}
                {type === 'qrcode' && <QRCodes />}
                {type === 'survey' && <Surveys />}
            </Paper>
        </>
    );
};

export default Dashboard;
