import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import FilterListIcon from '@mui/icons-material/FilterList';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ThemedChip from '../themedChip/ThemedChip';
import ArchiveIcon from '@mui/icons-material/Archive';

//todo tooltip for long topic, need to use forwardRef on the Topic component

const Type = ({ type }) => {
    switch (type) {
        case 'public':
            return (
                <Chip
                    size="small"
                    icon={<VisibilityIcon fontSize="small" />}
                    label="Public Group"
                    variant="outlined"
                />
            );
        case 'archive':
            return (
                <Chip
                    size="small"
                    icon={<ArchiveIcon fontSize="small" />}
                    label="Archive Group"
                    variant="outlined"
                />
            );
        default:
            return (
                <Chip
                    size="small"
                    icon={<VisibilityOffIcon fontSize="small" />}
                    label="Private Group"
                    variant="outlined"
                />
            );
    }
};

const NavigationItem = ({ item, type, selected, onSelect, onSetSelectedItem, action, onFilterByTag }) => {

    const handleFilterByTag = (event, id) => {
        event.stopPropagation();
        onFilterByTag(id);
    };

    const ListItemInner = () => {
        if (type === 'segment') {
            return (
                <>
                    <ListItemIcon sx={{ minWidth: 'unset', marginX: 1.5 }}>
                        <JoinInnerIcon fontSize="small" aria-label="Segment" />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={(
                            <Typography
                                variant="body2"
                                sx={{ fontWeight: 500 }}
                            >
                                {item.name}
                            </Typography>
                        )}
                        secondary={(
                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                <FilterListIcon sx={{ fontSize: 'inherit', marginRight: 0.5 }} />
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {item.filters.length}
                                </Typography>
                            </Box>
                        )}
                    />
                </>
            );
        }

        return (
            <>
                {Boolean(onSelect) && (
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selected}
                            tabIndex={-1}
                            disableRipple
                            onChange={onSelect}
                        />
                    </ListItemIcon>
                )}
                <ListItemText
                    disableTypography
                    primary={(
                        <Typography
                            variant="body2"
                            sx={{ fontWeight: 500 }}
                        >
                            {item.name}
                        </Typography>
                    )}
                    secondary={(
                        <>
                            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                <Type type={item.type} />
                                <Chip
                                    size="small"
                                    icon={<PersonIcon fontSize="small" />}
                                    label={`${item.contacts?.toLocaleString() ?? 0} Contacts`}
                                    variant="outlined"
                                />
                            </Stack>
                            {item.tags.length > 0 && (
                                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                    {item.tags.map(tag => (
                                        <ThemedChip
                                            key={tag.id}
                                            chipProps={{
                                                size: 'small',
                                                label: tag.name,
                                                icon: <SellOutlinedIcon fontSize="small" />,
                                                onClick: e => handleFilterByTag(e, tag.id)
                                            }}
                                            colour={tag.colour}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </>
                    )}
                />
            </>
        );
    };

    // todo improve this, there is a subtly different amount of padding between a clickable and non-clickable navigation items
    // this is because you lied when you were 17

    return (
        <>
            <Divider />
            <ListItem
                disablePadding={Boolean(onSetSelectedItem)}
                secondaryAction={action}
            >
                {onSetSelectedItem ? (
                    <ListItemButton
                        dense
                        selected={selected}
                        onClick={() => onSetSelectedItem(item)}
                    >
                        <ListItemInner />
                    </ListItemButton>
                ) : (
                    <ListItemInner />
                )}
            </ListItem>
        </>
    );
};

export default NavigationItem;