/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DevicesIcon from '@mui/icons-material/Devices';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigationContainer from '../navigation/NavigationContainer';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import SpamCheckDialog from './dialogs/SpamCheckDialog';
import CampaignPreviewDialog from './dialogs/CampaignPreviewDialog';
import EmailDetailsDialog from './dialogs/EmailDetailsDialog';
import DocumentsDialog from './dialogs/DocumentsDialog';
import useAccount from '../hooks/useAccount';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import ConditionPickerDialog from '../stripoEditor/dialogs/ConditionPickerDialog';
import { v4 as uuidv4 } from 'uuid';
import AlertBar from '../alertBar/AlertBar';

const disabledButton = {
    '&:disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
};

const drawerWidth = 400;

const mergeTags = [
    { label: 'Title', value: '[title]' },
    { label: 'First Name', value: '[firstname]' },
    { label: 'Last Name', value: '[lastname]' },
    { label: 'Company Name', value: '[companyname]' },
    { label: 'Email Address', value: '[emailaddress]' },
    { label: 'Date of Birth', value: '[dateofbirth]' },
    { label: 'Job Title', value: '[jobtitle]' },
    { label: 'Mobile Phone', value: '[mobilephone]' },
    { label: 'Address 1', value: '[address1]' },
    { label: 'Address 2', value: '[address2]' },
    { label: 'Address 3', value: '[address3]' },
    { label: 'City', value: '[city]' },
    { label: 'County', value: '[county]' },
    { label: 'Postcode', value: '[postcode]' },
    { label: 'Country', value: '[country]' },
    { label: 'Telephone Number', value: '[telno]' },
    { label: 'View Online Link', value: '[link]' }
];

const AlertBarComponent = ({ email, customerId }) => {
    const isMyTemplate = email.customerId === customerId;
    const colour = isMyTemplate ? 'success' : 'warning';
    const text = isMyTemplate ? 'your own' : 'a customer\'s';

    return (
        <AlertBar severity={colour} variant="filled">
            You are updating {text} email/template
        </AlertBar>
    );
};

const StripoEditor = ({ type = 'email', email, onUpdateEmail, onNext, nextButtonText = 'Next', title, showAlert }) => {
    const [isCodeEditorShown, setIsCodeEditorShown] = useState(false);
    const [spamCheckResult, setSpamCheckResult] = useState(null);
    const [campaignPreview, setCampaignPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [emailDetailsDialogOpen, setEmailDetailsDialogOpen] = useState(false);
    const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);
    const { customerId, customFields, customFonts, customColours, smtpUsername, hasCustomSmtpConfiguration } = useAccount();
    const onFileSelectCallback = useRef();
    const onCancelCallback = useRef();
    const { showSnackbar } = useSnackbar();
    const isInitRef = useRef(isInitialising);
    const { handleGet, handlePost } = useApi();

    const parser = new DOMParser();
    const serializer = new XMLSerializer();

    const [showConditionPicker, setShowConditionPicker] = useState(false);
    const [condition, setCondition] = useState(null);

    const selectConditionsCallback = useRef();

    const { Stripo, StripoApi } = window;

    const openConditionPicker = (onSelectCallback, appliedCondition) => {
        selectConditionsCallback.current = onSelectCallback;

        appliedCondition && setCondition(appliedCondition);
        setShowConditionPicker(true);
    };

    // dynamic content

    const handleProcessDynamicContent = html => {
        if (!hasCustomSmtpConfiguration) {
            return html;
        }

        const doc = parser.parseFromString(html, 'text/html');

        const bodyNode = doc.querySelector('body');

        const conditionsAttr = bodyNode.getAttribute('esd-custom-display-conditions');

        if (!conditionsAttr) {
            return html;
        }

        const conditions = JSON.parse(conditionsAttr);

        const ids = conditions.map(e => e.id);

        //find all conditional blocks
        const esdNodes = bodyNode.querySelectorAll('*[esd-custom-display-condition]');

        const nodeIds = [];

        esdNodes.forEach(e => {
            nodeIds.push(e.getAttribute('esd-custom-display-condition'));
        });

        //if more nodeIds than ids, we have a duplicate
        if (nodeIds?.length === ids?.length) {
            return html;
        }

        const duplicates = nodeIds.filter((e, i, a) => a.indexOf(e) !== i);

        //for each duplicate node, give it a new id and add a condition to match with a new nzId too
        esdNodes.forEach(e => {
            const oldId = e.getAttribute('esd-custom-display-condition');

            if (!duplicates.includes(oldId)) {
                return;
            }

            const existingCondition = conditions.find(e => e.id === oldId);

            const newId = new Date().getTime().toString();
            const newNzDynamicId = uuidv4().split('-')[0];

            e.setAttribute('esd-custom-display-condition', newId);

            const existingBefore = existingCondition.beforeScript;
            const existingAfter = existingCondition.afterScript;

            conditions.push({
                ...existingCondition,
                beforeScript: existingBefore.replace(new RegExp('id=\"(.*?)\"'), `id=\"${newNzDynamicId}\"`),
                afterScript: existingAfter.replace(new RegExp('id=\"(.*?)\"'), `id=\"${newNzDynamicId}\"`),
                id: newId
            });
        });

        const stringyConditions = JSON.stringify(conditions);

        bodyNode.setAttribute('esd-custom-display-conditions', stringyConditions);

        const newHtml = serializer.serializeToString(doc);

        return newHtml;
    };

    // save

    const handleSave = ({ autoSave = false }) => {
        !isInitRef.current && StripoApi.getTemplate((html, css) => {
            onUpdateEmail({
                html: handleProcessDynamicContent(html),
                css,
                autoSave
            });
        });
    };

    // preview

    const handlePreview = async () => {
        setIsLoading(true);

        await handleSave({ autoSave: true });

        StripoApi.compileEmail((error, html) => {
            //fyi: you can't define constants and expect them to update inside compileEmail

            if (error) {
                setIsLoading(false);
                console.error(error);
                showSnackbar('Preview encountered an error', 'error');
            }
            else if (html) {
                setIsLoading(false);
                setCampaignPreview(handleProcessDynamicContent(html));
            }
        });
    };

    // spam check

    const getSpamCheckResult = async (subject, fromName, fromEmail, html) => {
        setIsLoading(true);

        const response = await handlePost('proofs/mail-tester', {
            html,
            name: email.name,
            subject,
            fromName,
            fromEmail,
            googleAnalyticsCampaign: email.googleAnalyticsCampaign
        });

        const data = await response.json();

        if (data?.url) {
            setIsLoading(false);
            setSpamCheckResult(data.url);
        }
        else {
            setIsLoading(false);
            showSnackbar('Spam check error - send failed', 'error');
        }
    };

    const handleSendSpamCheck = async (subject, fromName, fromEmail) => {
        if (emailDetailsDialogOpen) {
            onUpdateEmail({
                subject,
                fromName,
                fromEmail
            });

            setEmailDetailsDialogOpen(false);
        }

        await StripoApi.compileEmail((error, html) => {
            if (error) {
                console.error(error);
                showSnackbar('Spam check error - email compilation failed', 'error');
                return;
            }

            getSpamCheckResult(subject, fromName, fromEmail, handleProcessDynamicContent(html));
        });
    };

    const handleSpamCheck = async () => {
        await handleSave({ autoSave: true });

        if (!email.subject || !email.fromName || email.fromEmail) {
            setEmailDetailsDialogOpen(true);
        }
        else {
            handleSendSpamCheck(email.subject, email.fromName, email.fromEmail);
        }
    };

    // next

    const handleNext = async () => {
        // todo loading overlay
        await handleSave({ autoSave: true });

        StripoApi.compileEmail((error, html) => onNext(handleProcessDynamicContent(html)));
    };

    // init

    const handleStripoInitComplete = () => {
        setIsInitialising(false);
        isInitRef.current = false;
    };

    // document manager

    const handleSelectFile = url => {
        onFileSelectCallback.current(url);
        setDocumentsDialogOpen(false);
    };

    const handleCloseDocumentsDialog = () => {
        onCancelCallback.current();
        setDocumentsDialogOpen(false);
    };

    useEffect(() => {
        const cid = email.customerId || customerId;

        const filteredCustomFields = customFields.filter(({ display }) => display).map(({ name }) => ({ 'label': name, 'value': `[${name}]` }));

        const modulesFolder = (type === 'template' || type === 'pageTemplate')
            ? `cid_${cid}_template_${email.id}`
            : `cid_${cid}`;

        const templateId = (type === 'template' || type === 'pageTemplate') ? email.id : email.templateId ?? 0;

        const itemType = type === 'pageTemplate' ? 'template' : type;

        const apiRequestData = {
            emailId: `${itemType}_${email.id}`,
            templateId,
            customerId: cid,
            modulesFolder
        };

        Stripo.init({
            settingsId: 'stripoSettingsContainer',
            previewId: 'stripoPreviewContainer',
            codeEditorButtonId: 'codeEditorButton',
            undoButtonId: 'undoButton',
            redoButtonId: 'redoButton',
            html: email.html,
            css: email.css,
            apiRequestData,
            getAuthToken: callback => {
                handleGet('stripo/token')
                    .then(response => response.json())
                    .then(({ token }) => callback(token));
            },
            onToggleCodeEditor: value => setIsCodeEditorShown(value),
            onDataChanged: () => handleSave({ autoSave: true }),
            ignoreClickOutsideSelectors: ['#saveEmailButton', '#undoButton', '#redoButton', '#previewButton', '#spamCheckButton', '#conditionFieldPicker', '#conditionValueField', '#conditionValueSelect', '#conditionValueDatePicker', '#conditionFieldPickerInput'],
            mergeTags: (type !== 'page' && type !== 'pageTemplate') ? [
                {
                    category: 'Standard Fields',
                    entries: mergeTags
                },
                {
                    category: 'Custom Fields',
                    entries: filteredCustomFields
                }
            ] : [],
            ...((customFonts && customFonts.length > 0) && {
                editorFonts: {
                    showDefaultStandardFonts: true,
                    showDefaultNotStandardFonts: true,
                    customFonts
                }
            }),
            ...((customColours && customColours.length > 0) && {
                brandColorPaletteColors: customColours.map(colour => colour.value),
                brandColorPaletteLabel: 'Brand Colours'
            }),
            onTemplateLoaded: handleStripoInitComplete,
            notifications: {
                error: message => showSnackbar(message, 'error')
                //info: message => console.log('info', message),
                //success: message => console.log('success', message),
                //warn: message => console.log('warn', message),
                //loader: message => console.log('loader', message),
                //hide: message => console.log('hide', message)
            },
            externalFilesLibrary: {
                buttonText: 'Link to Document, Campaign, Landing Page or Survey',
                open: {
                    openLibrary: (onFileSelect, onCancel) => {
                        onFileSelectCallback.current = onFileSelect;
                        onCancelCallback.current = onCancel;

                        setDocumentsDialogOpen(true);
                    }
                }
            },
            conditionsEnabled: (hasCustomSmtpConfiguration && type === 'email'), //activation of the Display Conditions tab
            customConditionsEnabled: false, //ability to create Custom Display Conditions. Also enables customising chosen ones. Adds another step to choosing
            conditionCategories: [
                {
                    'type': 'EXTERNAL',
                    'category': 'Choose Field and Value',
                    openExternalDisplayConditionsDialog: openConditionPicker
                }
            ],
            enableNativeSpellChecker: true
        });

        return StripoApi.stop;
    }, []);

    // render

    const isEditorLoading = isInitialising || isLoading;

    return (
        <>
            {isEditorLoading && (
                <LoadingOverlay />
            )}
            <Box sx={{ visibility: isEditorLoading ? 'hidden' : 'visible' }}>
                <NavigationContainer width={drawerWidth}>
                    <div id="stripoSettingsContainer" />
                </NavigationContainer>
                <div style={{ marginLeft: drawerWidth, flexGrow: 1 }}>
                    <div style={{ position: 'relative', zIndex: 1 }}>
                        <AppBar position="static" color="inherit">
                            <Toolbar>
                                <div style={{ flexGrow: 1 }}>
                                    <Typography component="h1" variant="h6">
                                        {title ?? email.name}
                                    </Typography>
                                </div>
                                <Stack spacing={0.5} direction="row">
                                    <Tooltip title="Save">
                                        <IconButton id="saveEmailButton" onClick={handleSave} aria-label="save">
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton id="undoButton" aria-label="undo" sx={disabledButton}>
                                        <UndoIcon />
                                    </IconButton>
                                    <IconButton id="redoButton" aria-label="redo" sx={disabledButton}>
                                        <RedoIcon />
                                    </IconButton>
                                    <Tooltip title="View Source">
                                        <IconButton id="codeEditorButton" aria-label="view source">
                                            {isCodeEditorShown ? <CodeOffIcon /> : <CodeIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Preview Campaign">
                                        <IconButton id="previewButton" onClick={handlePreview} aria-label="preview">
                                            <DevicesIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {(type === 'email' && !smtpUsername) && (
                                        <Tooltip title="Spam Check">
                                            <IconButton id="spamCheckButton" onClick={handleSpamCheck} aria-label="spam check">
                                                <FactCheckOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Stack>
                                <Button onClick={handleNext} endIcon={<NavigateNextIcon />} sx={{ marginLeft: 2 }}>{nextButtonText}</Button>
                            </Toolbar>
                        </AppBar>
                        {showAlert && (
                            <AlertBarComponent
                                email={email}
                                customerId={customerId}
                            />
                        )}
                    </div>
                    <div id="stripoPreviewContainer" />
                </div>
            </Box>
            {campaignPreview && (
                <CampaignPreviewDialog
                    open={Boolean(campaignPreview)}
                    onClose={() => setCampaignPreview(null)}
                    html={campaignPreview}
                />
            )}
            {spamCheckResult && (
                <SpamCheckDialog
                    spamCheckResult={spamCheckResult}
                    onClose={() => setSpamCheckResult(null)}
                />
            )}
            {emailDetailsDialogOpen && (
                <EmailDetailsDialog
                    email={email}
                    onComplete={handleSendSpamCheck}
                    onClose={() => setEmailDetailsDialogOpen(false)}
                />
            )}
            {documentsDialogOpen && (
                <DocumentsDialog
                    onClose={handleCloseDocumentsDialog}
                    onSelectLink={handleSelectFile}
                />
            )}
            {showConditionPicker && (
                <ConditionPickerDialog
                    appliedCondition={condition}
                    onSubmit={condition => selectConditionsCallback.current(condition)}
                    onClose={() => setShowConditionPicker(false)}
                />
            )}
        </>
    );
};

export default StripoEditor;