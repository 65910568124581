import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PermissionsPicker from './PermissionsPicker';
import useSnackbar from '../../../hooks/useSnackbar';

const InviteUserDialog = ({ onSubmit, onClose }) => {
    const [emailAddress, setEmailAddress] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const { showSnackbar } = useSnackbar();

    const handleSubmit = e => {
        e.preventDefault();
        const parts = emailAddress.split('@');

        if (parts.length !== 2) {
            showSnackbar('Please enter a valid email address', 'error');
            return;
        }

        const domain = parts[1];

        if (!domain.includes('.')) {
            showSnackbar('Please enter a valid email address', 'error');
            return;
        }

        onSubmit({
            emailAddress,
            permissions: selectedPermissions
        });
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <DialogTitle>Invite User</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Invite another user to log into your NewZapp account.
                    </Typography>
                    <TextField
                        autoFocus
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.target.value)}
                        margin="dense"
                    />
                    <PermissionsPicker
                        selectedPermissions={selectedPermissions}
                        onChange={setSelectedPermissions}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Invite User</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default InviteUserDialog;