import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Search from '../contacts/Search';
import Sort from '../contacts/Sort';
import Grid from '@mui/material/Unstable_Grid2';

const SegmentsFilters = ({ segmentsSort, setSegmentsSort, openCreateSegmentDialog = null, setSegmentsSearchValue, segmentsTempSearchValue, setSegmentsTempSearchValue }) => {
    return (
        <Stack spacing={1} sx={{ marginY: 2, width: '100%' }}>
            <Grid container spacing={1}>
                {openCreateSegmentDialog && (
                    <Grid xs={6}>
                        <Button onClick={openCreateSegmentDialog} fullWidth>
                            Create Segment
                        </Button>
                    </Grid>
                )}
                <Grid xs={6}>
                    <Sort
                        sort={segmentsSort}
                        setSort={segmentsSort => setSegmentsSort(segmentsSort)}
                        fullWidth={true}
                        margin={false}
                    />
                </Grid>
            </Grid>
            <Search
                tempSearchValue={segmentsTempSearchValue}
                setTempSearchValue={setSegmentsTempSearchValue}
                onSearch={setSegmentsSearchValue}
                placeholder="Filter by Name"
            />
        </Stack>
    );
};

export default SegmentsFilters;