import React, { useEffect, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useApi from '../hooks/useApi';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import NavigationItem from '../contacts/NavigationItem';
import ConfirmRemoveContactDialog from '../contacts/dialogs/ConfirmRemoveContactDialog';
import ConfirmUnsubscribeContactDialog from '../contacts/dialogs/ConfirmUnsubscribeContactDialog';
import ConfirmResubscribeContactDialog from '../contacts/dialogs/ConfirmResubscribeContactDialog';
import ConfirmSuppressContactDialog from '../contacts/dialogs/ConfirmSuppressContactDialog';
import ConfirmUnsuppressContactDialog from '../contacts/dialogs/ConfirmUnsuppressContactDialog';
import UnsavedChangesDialog from '../contacts/dialogs/UnsavedChangesDialog';
import SendHistory from '../contacts/contact/SendHistory';
import GdprActions from '../contacts/contact/GdprActions';
import CustomFields from '../contacts/contact/CustomFields';
import useSnackbar from '../hooks/useSnackbar';
import usePrevious from '../hooks/usePrevious';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Details from './contact/Details';

const Status = ({ isUnsubscribed, hasBounced }) => {
    return (
        <>
            <Alert
                severity={isUnsubscribed ? 'error' : hasBounced ? 'warning' : 'info'}
            >
                {`${isUnsubscribed ? 'Unsubscribed: Does not receive any campaigns from your account' : hasBounced ? 'Soft Bounce: Contact may not receive campaigns from your account' : 'Suppressed: Does not receive any campaigns from your account'}`}
            </Alert>
            <Divider />
        </>
    );
};

const ListItems = ({ items, type, selectedItems, showCount = true, onSetSelectedItem = null }) => {
    if (items && items.length > 0) {
        return items.filter(item => item.groupType !== 'archive')
            .map(item => {
                const selected = selectedItems.includes(item.id);

                const handleSelectItem = () => {
                    onSetSelectedItem && onSetSelectedItem(item);
                };

                return (
                    <NavigationItem
                        key={item.id}
                        item={item}
                        type={type}
                        selected={selected}
                        onSetSelectedItem={handleSelectItem}
                        showCount={showCount}
                        action={(
                            <Checkbox
                                edge="end"
                                checked={selected}
                                tabIndex={-1}
                                disableRipple
                                onChange={handleSelectItem}
                                disabled={!onSetSelectedItem}
                            />
                        )}
                    />
                );
            });
    }

    return (
        <Typography
            variant="body2"
            sx={{ padding: 2, textAlign: 'center' }}
        >
            No {type}s found
        </Typography>
    );
};

const Contact = ({ onClose, id, onRefresh }) => {
    const { handleGet, handlePut, handleDelete } = useApi();
    const theme = useTheme();
    const { showSnackbar } = useSnackbar();
    const [dialog, setDialog] = useState(null);
    const [manageGroups, setManageGroups] = useState(false);
    const [expanded, setExpanded] = useState('details');
    const [contact, setContact] = useState(null);
    const [unsavedFields, setUnsavedFields] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState(null);
    const [segments, setSegments] = useState(null);
    const [updatedContactGroupIds, setUpdatedContactGroupIds] = useState([]);
    const [unsavedGroups, setUnsavedGroups] = useState(false);
    const [updatedCustomFields, setUpdatedCustomFields] = useState([]);
    const [newCustomFields, setNewCustomFields] = useState([]);
    const [newDetails, setNewDetails] = useState(null);
    const [isInitialising, setIsInitialising] = useState(true);

    const prevUpdatedContactGroupIds = usePrevious(updatedContactGroupIds);
    const prevUpdatedCustomFields = usePrevious(updatedCustomFields);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : 'none');
    };

    const handleFetchContact = async () => {
        const response = await handleGet(`contacts/${id}`);

        if (!response.ok) {
            setIsLoading(false);
            showSnackbar('Contact not found', 'error');
            console.error(response?.error);
            return;
        }

        const contact = await response.json();

        setContact(contact);

        setUpdatedContactGroupIds(contact.groupIds);
        setIsInitialising(false);
        setIsLoading(false);
    };

    const handleClose = () => {
        setDialog(null);
        onClose();
        setContact(null);
    };

    const handleRemoveContact = async () => {
        const response = await handleDelete(`contacts/${contact.id}`);

        if (!response.ok) {
            showSnackbar('Contact not found', 'error');
            console.error(response?.error);
            return;
        }

        showSnackbar('Contact deleted', 'success');

        handleClose();
        onRefresh();
    };

    const handleUpdateContact = async newContactDetails => {
        setIsLoading(true);

        const newContact = Object.assign(contact, newContactDetails);

        const response = await handlePut(`contacts/${id}`, newContact);

        if (!response.ok) {
            const statusCode = response?.status;
            let errorMessage = 'Error';

            switch (statusCode) {
                case 400:
                    errorMessage = (await response.json()).message;
                    break;
                case 404:
                    errorMessage = 'Contact not found';
                    break;
                case 409:
                    errorMessage = 'Email Address already exists';
                    break;
                default:
                    break;
            }

            showSnackbar(errorMessage, 'error');

            console.error(response?.error);
            setIsLoading(false);
            return;
        }

        if (contact && updatedContactGroupIds) {
            setUpdatedCustomFields([]);
            setNewDetails(null);
        }

        showSnackbar('Contact updated', 'success');
    };

    const handleFetchGroups = async () => {
        const response = await handleGet('groups');

        if (!response.ok) {
            showSnackbar('Groups not found', 'error');
            console.error(response?.error);
            return;
        }

        const groups = await response.json();

        setGroups(groups);
    };

    const handleUpdateContactGroupsDetailsCustomFields = async () => {
        const newContactData = {
            ...(updatedContactGroupIds && { groupIds: updatedContactGroupIds }),
            ...(newDetails && newDetails),
            customFields: newCustomFields?.length > 0 ? newCustomFields : contact.customFields
        };

        await handleUpdateContact(newContactData);

        await handleFetchGroups();
        handleFetchContact();
        onRefresh();
    };

    const handleUnsubscribeContact = async isUnsubscribed => {
        const newContactData = {
            isUnsubscribed,
            ...(newDetails && newDetails),
            ...(updatedContactGroupIds && { groupIds: updatedContactGroupIds }),
            customFields: newCustomFields?.length > 0 ? newCustomFields : contact.customFields
        };

        await handleUpdateContact(newContactData);

        handleClose();
        onRefresh();
    };

    const handleSuppressContact = async isSuppressed => {
        const newContactData = {
            isSuppressed,
            ...(newDetails && newDetails),
            ...(updatedContactGroupIds && { groupIds: updatedContactGroupIds }),
            customFields: newCustomFields?.length > 0 ? newCustomFields : contact.customFields
        };

        await handleUpdateContact(newContactData);

        handleFetchContact();
        onRefresh();
    };

    const handleFetchSegments = async () => {
        const response = await handleGet('segments');

        if (!response.ok) {
            showSnackbar('Segments not found', 'error');
            console.error(response?.error);
            return;
        }

        const segments = await response.json();

        setSegments(segments);
    };

    const handleClickClose = () => {
        if (isLoading) {
            return;
        }

        if (unsavedFields || unsavedGroups) {
            setDialog(
                <UnsavedChangesDialog
                    onClose={() => setDialog(null)}
                    onDiscard={handleClose}
                />
            );

            return;
        }

        handleClose();
    };

    const handleToggleGroup = item => {
        const currentlySelected = updatedContactGroupIds.includes(item.id);

        if (currentlySelected) {
            setUpdatedContactGroupIds(updatedContactGroupIds.filter(id => id !== item.id));
        }
        else {
            setUpdatedContactGroupIds([...updatedContactGroupIds, item.id]);
        }
    };

    const handleOpenSubscribeDialog = () => {
        if (contact.isUnsubscribed || contact.hasBounced) {
            setDialog(
                <ConfirmResubscribeContactDialog
                    onClose={() => setDialog(null)}
                    onConfirm={() => handleUnsubscribeContact(false)}
                    emailAddress={contact?.emailAddress}
                />
            );
        }

        if (!contact.isUnsubscribed) {
            setDialog(
                <ConfirmUnsubscribeContactDialog
                    onClose={() => setDialog(null)}
                    onConfirm={() => handleUnsubscribeContact(true)}
                    emailAddress={contact?.emailAddress}
                />
            );
        }
    };

    const handleOpenSuppressionDialog = () => {
        if (contact.isSuppressed) {
            setDialog(
                <ConfirmUnsuppressContactDialog
                    onClose={() => setDialog(null)}
                    onConfirm={() => handleSuppressContact(false)}
                    emailAddress={contact?.emailAddress}
                />
            );
        }
        else {
            setDialog(
                <ConfirmSuppressContactDialog
                    onClose={() => setDialog(null)}
                    onConfirm={() => handleSuppressContact(true)}
                    emailAddress={contact?.emailAddress}
                />
            );
        }
    };

    const handleToggleManageGroups = e => {
        e.stopPropagation();
        setManageGroups(e.target.checked);
    };

    useEffect(() => {
        if (!contact?.id) {
            return;
        }

        if (expanded === 'groups' && !groups) {
            handleFetchGroups();
        }

        if (expanded === 'segments' && !segments) {
            handleFetchSegments();
        }
    }, [expanded]);

    useEffect(() => {
        if (id) {
            handleFetchContact();
            handleFetchGroups();
        }
    }, [id]);

    useEffect(() => {
        if (!contact) {
            return;
        }

        if (updatedContactGroupIds !== prevUpdatedContactGroupIds) {
            if (JSON.stringify(updatedContactGroupIds) !== JSON.stringify(contact.groupIds)) {
                setUnsavedGroups(true);
            }
            else {
                setUnsavedGroups(false);
            }
        }
    }, [updatedContactGroupIds]);

    useEffect(() => {
        if (!contact) {
            return;
        }

        if (updatedCustomFields !== prevUpdatedCustomFields) {
            const newCustomFields = contact.customFields.map(field => {
                const updatedField = updatedCustomFields.find(e => e.id === field.id);

                //we need this value even if it's null
                if (updatedField) {
                    return { ...field, value: updatedField.value };
                }

                return field;
            });

            setNewCustomFields(newCustomFields);
        }
    }, [updatedCustomFields]);

    //DETAILS

    // eslint-disable-next-line no-unused-vars
    const [isValid, setIsValid] = useState(true);
    const [unsavedDetails, setUnsavedDetails] = useState(false);
    const [isReverting, setIsReverting] = useState(false);

    useEffect(() => {
        setUnsavedFields(unsavedDetails);
    }, [unsavedDetails]);

    if (isInitialising) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={Boolean(contact?.id)}
                onClose={handleClickClose}
                variant="temporary"
                BackdropProps={{ invisible: true }}
                ModalProps={{ keepMounted: false }}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.background.default
                    }
                }}
            >
                <Toolbar />
                <Box sx={{
                    overflow: 'auto',
                    width: 840
                }}>
                    {contact ? (
                        <>
                            <AppBar
                                position="sticky"
                                color="inherit"
                            >
                                <Toolbar>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6">
                                            {contact.emailAddress}
                                        </Typography>
                                    </Box>
                                    <IconButton onClick={handleClickClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            {(contact.isUnsubscribed || contact.hasBounced || contact.isSuppressed) && (
                                <Status
                                    isUnsubscribed={contact.isUnsubscribed}
                                    hasBounced={contact.hasBounced}
                                />
                            )}
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: 2 }}>
                                {(!contact.isUnsubscribed && !contact.hasBounced) && (
                                    <Button onClick={handleOpenSuppressionDialog}>
                                        {contact.isSuppressed ? 'Unsuppress' : 'Suppress'}
                                    </Button>
                                )}
                                {(!contact.isUnsubscribed && !contact.hasBounced) && ( //separated from above to maintain stack
                                    <Button
                                        onClick={() => setDialog(
                                            <ConfirmRemoveContactDialog
                                                onClose={() => setDialog(null)}
                                                onConfirm={handleRemoveContact}
                                                emailAddress={contact?.emailAddress}
                                            />
                                        )}
                                    >
                                        Delete
                                    </Button>
                                )}
                                <Button onClick={handleOpenSubscribeDialog}>
                                    {contact.isUnsubscribed ? 'Resubscribe' : 'Unsubscribe'}
                                </Button>
                            </Stack>
                            <Collapse in={unsavedDetails || unsavedFields || unsavedGroups} sx={{ px: 2 }}>
                                <Alert
                                    severity={isValid ? 'info' : 'error'}
                                    action={(
                                        <Stack direction="row" spacing={1}>
                                            <Button
                                                onClick={handleUpdateContactGroupsDetailsCustomFields}
                                                size="small"
                                                disabled={
                                                    isLoading &&
                                                    (
                                                        !(unsavedDetails && isValid) ||
                                                        !(unsavedFields && isValid) ||
                                                        !unsavedGroups
                                                    )
                                                }
                                            >
                                                Save changes
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setIsReverting(true);
                                                    setUpdatedContactGroupIds(contact.groupIds);
                                                }}
                                                size="small"
                                                disabled={isLoading}
                                                variant="outlined"
                                            >
                                                Revert
                                            </Button>
                                        </Stack>
                                    )}
                                    sx={{ display: 'flex', padding: 2, marginBottom: 2 }}
                                >
                                    {isLoading ? 'Updating...' : `There are unsaved changes.${isValid ? '' : ' Email Address is invalid, please correct before saving.'}`}
                                </Alert>
                            </Collapse>
                            <Box px={2} pb={2}>
                                <Accordion expanded={expanded === 'details'} onChange={handleChange('details')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="details"
                                        id="details-header"
                                    >
                                        <Typography>Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Details
                                            contact={contact}
                                            isLoading={isLoading}
                                            panelOpen={expanded === 'details'}
                                            setNewDetails={setNewDetails}
                                            setUnsavedDetails={setUnsavedDetails}
                                            setIsReverting={setIsReverting}
                                            isReverting={isReverting}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'custom'} onChange={handleChange('custom')} disabled={contact.customFields.length === 0}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="custom"
                                        id="custom-header"
                                    >
                                        <Typography>Custom Fields</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomFields
                                            customFields={contact.customFields}
                                            isLoading={isLoading}
                                            setUnsavedFields={unsavedFields => setUnsavedFields(unsavedFields)}
                                            updatedCustomFields={updatedCustomFields}
                                            setUpdatedCustomFields={updatedCustomFields => setUpdatedCustomFields(updatedCustomFields)}
                                            setIsReverting={setIsReverting}
                                            isReverting={isReverting}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'groups'} onChange={handleChange('groups')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="groups"
                                        id="groups-header"
                                    >
                                        <Typography>Groups</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup sx={{ marginBottom: 1 }}>
                                            <FormControlLabel control={<Switch checked={manageGroups} onChange={handleToggleManageGroups} />} label="Manage Groups" />
                                        </FormGroup>
                                        {manageGroups ? (
                                            <>
                                                <ListItems
                                                    onSetSelectedItem={handleToggleGroup}
                                                    items={groups}
                                                    type="group"
                                                    selectedItems={updatedContactGroupIds}
                                                />
                                            </>
                                        ) : (
                                            <ListItems
                                                items={updatedContactGroupIds ? groups?.filter(e => updatedContactGroupIds.includes(e.id)) : []}
                                                type="group"
                                                selectedItems={updatedContactGroupIds}
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'segments'} onChange={handleChange('segments')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="segments"
                                        id="segments-header"
                                    >
                                        <Typography>Segments</Typography>
                                    </AccordionSummary>
                                    {segments && (
                                        <AccordionDetails>
                                            <ListItems
                                                items={contact.segmentIds ? segments.filter(e => contact.segmentIds.includes(e.id)) : []}
                                                type="segment"
                                                selectedItems={contact.segmentIds}
                                                showCount={false}
                                            />
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                                <Accordion expanded={expanded === 'sendHistory'} onChange={handleChange('sendHistory')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="sendHistory"
                                        id="sendHistory-header"
                                    >
                                        <Typography>Send History</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SendHistory id={id} />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'gdpr'} onChange={handleChange('gdpr')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="gdpr"
                                        id="gdpr-header"
                                    >
                                        <Typography>GDPR</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <GdprActions id={id} />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </>
                    ) : (
                        <Stack spacing={2} sx={{ padding: 2 }}>
                            <Skeleton variant="text" />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        </Stack>
                    )}
                </Box>
            </Drawer>
            {dialog}
        </>
    );
};

export default Contact;