import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import PermissionsPicker from '../../../account/users/dialogs/PermissionsPicker';

const AddUserModal = ({ shown, email, name, handleHide, handleChange, handleNameChange, onSubmit, isLoading }) => {
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const handleSubmit = async e => {
        e.preventDefault();
        await onSubmit(selectedPermissions);
        setSelectedPermissions([]);
    };

    return (
        <Dialog
            open={shown}
            onClose={handleHide}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please enter the email address of the new user. This email address will receive an invitation to join the account.
                </DialogContentText>
                <FormGroup>
                    <TextField
                        fullwidth
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Name"
                        autoComplete="off"
                        value={name}
                        onChange={handleNameChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        fullwidth
                        type="email"
                        variant="outlined"
                        size="small"
                        label="Email"
                        autoComplete="off"
                        value={email}
                        onChange={handleChange}
                        placeholder="name@example.com"
                    />
                </FormGroup>
                <PermissionsPicker
                    selectedPermissions={selectedPermissions}
                    onChange={setSelectedPermissions}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleHide} variant="outlined">
                    Close
                </Button>
                <Button
                    onClick={handleSubmit}
                    endIcon={isLoading ? <CircularProgress size={16} style={{ color: '#FFF' }} /> : null}
                >
                    {isLoading ? 'Working' : 'Invite user'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserModal;