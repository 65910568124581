import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Container from '@mui/material/Container';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useAccount from '../../hooks/useAccount';
import useApi from '../../hooks/useApi';
import Card from '@mui/material/Card';
import TitleBar from '../../titleBar/TitleBar';
import AlertBar from '../../alertBar/AlertBar';
import Chip from '@mui/material/Chip';
import SettingsIcon from '@mui/icons-material/Settings';
import Alert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useSnackbar from '../../hooks/useSnackbar';

const AccountSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [account, setAccount] = useState();
    const [emailAddress, setEmailAddress] = useState('');
    const [lowQuotaNotification, setLowQuotaNotification] = useState(false);
    const [lowQuotaLevel, setLowQuotaLevel] = useState(0);
    const [doubleOptIn, setDoubleOptIn] = useState(false);
    const [company, setCompany] = useState('');
    const [logo, setLogo] = useState();
    const { handleFetch, handleGet, handlePut, handleDelete } = useApi();
    const { isInternalComms } = useAccount();
    const { showSnackbar } = useSnackbar();

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            setIsSubmitting(false);
            return;
        }

        const data = await response.json();

        setAccount(data);

        setEmailAddress(data.emailAddress);
        setLowQuotaNotification(data.lowQuotaNotification);
        setLowQuotaLevel(data.lowQuotaLevel);
        setDoubleOptIn(data.doubleOptIn);
        setCompany(data.company);
        setLogo(data.logo);

        setIsLoading(false);
        setIsSubmitting(false);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        const response = await handlePut('account', {
            emailAddress,
            lowQuotaNotification,
            lowQuotaLevel,
            doubleOptIn,
            company
        });

        if (response.ok) {
            await handleFetchAccount();
        }
        else {
            // todo handle error
            console.error(response);
        }
    };

    const onChangeLogo = async e => {
        setIsSubmitting(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        const response = await handleFetch('account/company-logo', {
            method: 'post',
            body: data
        });

        if (!response.ok) {
            showSnackbar('Error uploading logo', 'error');
            setIsSubmitting(false);
        }

        await handleFetchAccount();
    };

    const onDeleteLogo = async () => {
        const response = await handleDelete('account/company-logo');

        if (response.ok) {
            await handleFetchAccount();
        }
        else {
            // todo handle error
            console.error(response.statusText);
        }
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        setHasUnsavedChanges(emailAddress !== account?.emailAddress
            || lowQuotaNotification !== account?.lowQuotaNotification
            || lowQuotaLevel !== account?.lowQuotaLevel
            || doubleOptIn !== account?.doubleOptIn
            || company !== account?.company
            || logo !== account?.logo);
    }, [account, emailAddress, lowQuotaNotification, lowQuotaLevel, doubleOptIn, company, logo]);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <TitleBar title="Settings" />
            <AlertBar severity="info">
                Account Settings are being moved so that they are in the relevant section and easier to find when you need them.
                Each section will now have a Settings Icon <SettingsIcon sx={{ verticalAlign: 'middle' }} /> in the top right of the UI
                <br />
                <br />
                <ul>
                    <li>Manage Topics is now in Create & Send</li>
                    <li>Custom Fields is now in Create & Send and in Contacts</li>
                    <li>Manage Documents is now in Create & Send</li>
                    <li>Manage Tags is now in Contacts</li>
                    <li>Sign Up Form and Double opt-in are now in Contacts</li>
                </ul>
            </AlertBar>

            <AlertBar
                shown={isLoading || hasUnsavedChanges}
                action={(
                    <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Save Changes
                    </Button>
                )}
                positionTop={128}
            >
                {isLoading ? 'Updating...' : 'You have unsaved changes.'}
            </AlertBar>

            <Container disableGutters>
                <Card sx={{ m: 2, p: 3 }}>
                    <Box mb={2}>
                        <Typography component="h2" variant="h6" gutterBottom>Notifications</Typography>

                        <TextField
                            value={emailAddress}
                            onChange={e => setEmailAddress(e.target.value)}
                            type="email"
                            label="Send system notifications to/from"
                            fullWidth={true}
                        />
                    </Box>

                    <Box my={2}>

                        <Typography component="h2" variant="h6" gutterBottom>Send</Typography>

                        <FormControlLabel
                            control={(
                                <Switch
                                    checked={lowQuotaNotification}
                                    onChange={e => setLowQuotaNotification(e.target.checked)}
                                    name="lowquota"
                                />
                            )}
                            label="Low quota notification"
                        />

                        <TextField value={lowQuotaLevel} onChange={e => setLowQuotaLevel(e.target.value)} disabled={!lowQuotaNotification} type="number" label="Low quota level" fullWidth={true} />

                    </Box>

                    {(isInternalComms && account?.allowedRecipients?.length > 0) && (
                        <Box my={2}>
                            <Typography component="h2" variant="h6" gutterBottom>Allowed Recipients</Typography>

                            {account.allowedRecipients.map(recipient => <Chip key={recipient.id} label={recipient.pattern} sx={{ marginRight: 1, marginBottom: 1 }} />)}
                        </Box>
                    )}

                    <Box my={2}>
                        <Typography component="h2" variant="h6" gutterBottom>Company Branding</Typography>

                        <TextField value={company} onChange={e => setCompany(e.target.value)} type="text" label="Company Name" fullWidth={true} />
                    </Box>
                </Card>

                <Card sx={{ m: 2, p: 1 }}>
                    <CardHeader
                        sx={{ marginRight: 1 }}
                        action={(
                            <>
                                <label htmlFor="upload-company-logo">
                                    <Button component="span">Upload</Button>
                                </label>
                                {logo && (
                                    <Button onClick={onDeleteLogo} sx={{ marginLeft: 2 }} color="error">Delete</Button>
                                )}
                            </>
                        )}
                        title={<Typography component="h1" variant="h6" sx={{ flex: 1 }}>Company Logo</Typography>}
                    />
                    <CardContent sx={{ paddingRight: 2, paddingTop: 0 }}>
                        <Alert severity="info" sx={{ marginBottom: 2 }}>
                            Images greater than 300px wide are automatically resized to a max width of 300px on upload.<br />
                            Supported file types: .JPG, .JPEG, .PNG
                        </Alert>
                        {logo && (
                            <Box component="img" alt={company} src={logo} sx={{ maxWidth: 300, mb: 1 }} />
                        )}
                        <FormGroup row>
                            <Box sx={{ display: 'none' }}>
                                <Box component="input" type="file" accept="image/*" onChange={onChangeLogo} id="upload-company-logo" />
                            </Box>
                        </FormGroup>
                    </CardContent>
                </Card>

                {(isSubmitting) && (
                    <LoadingOverlay />
                )}

            </Container>
        </>
    );
};

export default AccountSettings;