import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';

const SmtpTestResultsDialog = ({ open, succeeded, message, onClose }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>SMTP Configuration Test</DialogTitle>
            <DialogContent>
                {succeeded && (
                    <Alert severity="success">
                        <AlertTitle>SMTP configuration test passed</AlertTitle>
                        Your SMTP connection is configured correctly.
                    </Alert>
                )}
                {!succeeded && (
                    <Stack spacing={2}>
                        <Alert severity="error">
                            <AlertTitle>SMTP configuration test failed</AlertTitle>
                            We failed to connect to your SMTP server with the following message:
                            <Box pt={2}>
                                <Typography variant="caption">{message}</Typography>
                            </Box>
                        </Alert>
                        <DialogContentText>
                            For more information please contact your account manager or system administrator.
                        </DialogContentText>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SmtpTestResultsDialog;