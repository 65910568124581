import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CampaignSelect from './CampaignSelect';

const CampaignSelectDialog = ({ onClose, onSubmit, setCampaignId, currentlySelected }) => {
    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
        onClose();
    };

    return (
        <Dialog
            onClose={onClose}
            open={true}
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle>Select a Campaign</DialogTitle>
            <DialogContent>
                <CampaignSelect
                    type="email"
                    onSetCampaign={({ id }) => setCampaignId(id)}
                    currentlySelected={currentlySelected}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Confirm Selection</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CampaignSelectDialog;