import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TitleBar from '../../titleBar/TitleBar';
import buildUrl from '../../buildUrl';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import DeleteAccountsDialog from './dialogs/DeleteAccountsDialog';
import CreateAccountDialog from './dialogs/CreateAccountDialog';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useSnackbar from '../../hooks/useSnackbar';

const fields = [
    { property: 'id', type: 'number', label: 'CID', columnWidth: '7%' },
    { property: 'customerName', type: 'text', label: 'Customer', columnWidth: '22%' },
    { property: 'licence', type: 'text', label: 'Licence', columnWidth: '22%' },
    { property: 'renewalDate', type: 'date', label: 'Renewal Date', columnWidth: '10%' },
    { property: 'lastLoggedIn', type: 'date', label: 'Last Login', columnWidth: '10%' },
    { property: 'mta', type: 'text', label: 'MTA', columnWidth: '16%' },
    { property: 'status', type: 'text', label: 'Status', columnWidth: '7%' }
];

const availableFilters = [
    { field: 'id', label: 'CID' },
    { field: 'resellerId', label: 'Reseller ID' },
    { field: 'emailAddress', label: 'Email' },
    { field: 'customerName', label: 'Customer Name' },
    { field: 'licence', label: 'Licence' },
    { field: 'mta', label: 'MTA' }
];

const rowsPerPageOptions = [10, 20, 50];
const cellStyles = { maxWidth: '350px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' };

const Accounts = ({ showHamburger, onClickHamburger }) => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { handleGet, handlePost, handleDelete } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const { showSnackbar } = useSnackbar();

    const [tempValue, setTempValue] = useState(queryParams.get('tempValue') || '');
    const [status, setStatus] = useState(queryParams.get('status') || '');
    const [filterValue, setFilterValue] = useState(queryParams.get('filterValue') || '');
    const [selectedField, setSelectedField] = useState(queryParams.get('selectedField') || 'id');
    const [orderBy, setOrderBy] = useState(queryParams.get('orderBy') || 'id');
    const [isDescending, setIsDescending] = useState(queryParams.get('isDescending') || true);
    const [rowsPerPage, setRowsPerPage] = useState(queryParams.get('rowsPerPage') || rowsPerPageOptions[1]);
    const [page, setPage] = useState(queryParams.get('page') || 0);

    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [showDeleteAccountsDialog, setShowDeleteAccountsDialog] = useState(false);
    const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);

    const handleFetchCount = async () => {
        const url = buildUrl('superadmin/accounts/count', {
            status,
            ...(filterValue && { field: selectedField, value: filterValue })
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            setCount(data);
        }
    };

    const handleFetchAccounts = async () => {
        const url = buildUrl('superadmin/accounts', {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending,
            status,
            ...(filterValue && { field: selectedField, value: filterValue })
        });

        const response = await handleGet(url);

        if (response) {
            const data = await response.json();
            setAccounts(data);
        }
    };

    const handleSortAccounts = field => {
        if (orderBy === field) {
            setIsDescending(!isDescending);
        }
        else {
            // todo introduce field types
            setIsDescending(field === 'id' || field === 'renewalDate' || field === 'lastLoggedIn');
        }

        setOrderBy(field);
    };

    useEffect(() => {
        handleFetchCount();
    }, [status, filterValue]);

    useEffect(() => {
        setPage(0);
    }, [status, filterValue, orderBy, isDescending, rowsPerPage]);

    useEffect(() => {
        queryParams.set('tempValue', tempValue);
        queryParams.set('status', status);
        queryParams.set('filterValue', filterValue);
        queryParams.set('selectedField', selectedField);
        queryParams.set('orderBy', orderBy);
        queryParams.set('isDescending', isDescending);
        queryParams.set('rowsPerPage', rowsPerPage);
        queryParams.set('page', page);
        history.replace({ search: queryParams.toString() });

        handleFetchAccounts();
    }, [status, filterValue, orderBy, isDescending, rowsPerPage, page]);

    // Reset table

    const handleReset = e => {
        e.preventDefault();

        // clear state
        setPage(0);
        setOrderBy('id');
        setIsDescending(true);
        setSelectedField('id');
        setStatus('');
        setFilterValue('');
        setTempValue('');
    };

    // Load account details

    const handleClickAccount = ({ id }) => {
        history.push({
            pathname: `/superadmin/accounts/${id}`
        });
    };

    // Select items

    const handleSelectAccount = (e, id) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedAccounts(selectedAccounts.includes(id) ? selectedAccounts.filter(a => a !== id) : [...selectedAccounts, id]);
    };

    const handleClickSelectAll = e => {
        setSelectedAccounts(e.target.checked ? accounts.filter(a => a.okToDelete).map(a => a.id) : []);
    };

    // Delete accounts functions

    const handleDeleteAccounts = async () => {
        const ids = [...selectedAccounts];
        setSelectedAccounts([]);
        setShowDeleteAccountsDialog(false);

        const url = `superadmin/accounts?${ids.map(id => `ids=${id}`).join('&')}`;
        await handleDelete(url);
        await handleFetchAccounts();
    };

    // Create Account

    const handleCreateAccount = async (payload) => {
        setShowCreateAccountDialog(false);
        setIsLoading(true);

        const response = await handlePost('superadmin/accounts', payload);

        setIsLoading(false);

        if (!response.ok) {
            showSnackbar('Account creation failed', 'error');
            return;
        }
        else {
            showSnackbar('Account created successfully', 'success');
        }

        const { id } = await response.json();

        handleClickAccount({ id });
    };

    // Filter

    const handleSubmitFilter = e => {
        e.preventDefault();
        setFilterValue(tempValue.trim());
    };

    // Search on change. Auto switch from CID to customer name when target value contains letters

    const handleChangeSearch = e => {
        setTempValue(e.target.value);

        if (selectedField === 'id' && isNaN(e.target.value)) {
            setSelectedField('customerName');
            setTempValue(e.target.value);
        }
    };

    // Render

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Accounts</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>

            <Paper sx={{ m: 2 }}>
                <Toolbar
                    disableGutters
                    sx={{ px: 2 }}
                >
                    <Box style={{ flexGrow: 1 }}>
                        <form onSubmit={handleSubmitFilter}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <FormControl margin="none">
                                        <Select
                                            value={status}
                                            onChange={e => setStatus(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            displayEmpty
                                        >
                                            <MenuItem value="">All Accounts</MenuItem>
                                            <MenuItem value="active">Active</MenuItem>
                                            <MenuItem value="expired">Expired</MenuItem>
                                            <MenuItem value="cancelled">Cancelled</MenuItem>
                                            <MenuItem value="closed">Closed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl margin="none">
                                        <Select
                                            value={selectedField}
                                            onChange={e => setSelectedField(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        >
                                            {availableFilters.map(({ field, label }) => (
                                                <MenuItem value={field} key={field}>{label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl margin="none">
                                        <TextField
                                            value={tempValue}
                                            onChange={e => handleChangeSearch(e)}
                                            variant="outlined"
                                            margin="none"
                                            size="small"
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            disableRipple
                                                            edge="end"
                                                            onClick={handleReset}
                                                            disabled={!Boolean(tempValue)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        type="submit"
                                        variant="outlined"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    {selectedAccounts.length > 0 ? (
                        <Button
                            color="error"
                            onClick={() => setShowDeleteAccountsDialog(true)}
                            startIcon={<DeleteIcon />}
                        >
                            Delete Accounts
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setShowCreateAccountDialog(true)}
                            sx={{ ml: 1 }}
                            startIcon={<AddIcon />}
                        >
                            New Account
                        </Button>
                    )}
                </Toolbar>
                <Divider />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedAccounts.length > 0 && selectedAccounts.length === accounts.filter(a => a.okToDelete).length}
                                        indeterminate={selectedAccounts.length > 0 && selectedAccounts.length !== accounts.filter(a => a.okToDelete).length}
                                        disabled={accounts.filter(a => a.okToDelete).length === 0}
                                        onChange={handleClickSelectAll}
                                    />
                                </TableCell>
                                {fields.map(({ property, label, align }) => (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        component="th"
                                        sx={cellStyles}
                                    >
                                        <TableSortLabel
                                            active={orderBy === property}
                                            direction={isDescending ? 'desc' : 'asc'}
                                            onClick={() => handleSortAccounts(property)}
                                        >
                                            {label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accounts.map(account => {
                                const cells = fields.map(({ property, type, align }) => type === 'date' ? (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {account[property] ? format((new Date(account[property])), 'dd/MM/yyyy HH:mm:ss') : '-'}
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={property}
                                        align={align}
                                        sx={cellStyles}
                                    >
                                        {account[property] || '-'}
                                    </TableCell>
                                ));

                                const isEnabled = account.okToDelete;
                                const isSelected = selectedAccounts.includes(account.id);

                                return (
                                    <TableRow
                                        key={account.id}
                                        selected={isSelected}
                                        hover
                                        onClick={() => handleClickAccount(account)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected}
                                                disabled={!isEnabled}
                                                onClick={e => handleSelectAccount(e, account.id)}
                                                sx={cellStyles}
                                            />
                                        </TableCell>
                                        {cells}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Paper>

            {isLoading && (
                <LoadingOverlay />
            )}

            {showDeleteAccountsDialog && (
                <DeleteAccountsDialog
                    accounts={selectedAccounts}
                    onHide={() => setShowDeleteAccountsDialog(false)}
                    onConfirm={handleDeleteAccounts}
                />
            )}

            {showCreateAccountDialog && (
                <CreateAccountDialog
                    onHide={() => setShowCreateAccountDialog(false)}
                    onConfirm={handleCreateAccount}
                    isLoading={isLoading}
                />
            )}

        </>
    );
};

export default Accounts;