import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import useAccount from '../../hooks/useAccount';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TitleBar from '../../titleBar/TitleBar';
import InviteUserDialog from './dialogs/InviteUserDialog';
import EditUserDialog from './dialogs/EditUserDialog';
import DeleteUserDialog from './dialogs/DeleteUserDialog';
import Typography from '@mui/material/Typography';
import AlertBar from '../../alertBar/AlertBar';
import useSnackbar from '../../hooks/useSnackbar';

const dialogs = {
    INVITE_USER: 0,
    EDIT_USER: 1,
    DELETE_USER: 2
};

const Users = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dialog, setDialog] = useState(null);
    const [users, setUsers] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const { handleGet, handlePut, handlePost, handleDelete } = useApi();
    const { userLimit, emailAddress } = useAccount();
    const { showSnackbar } = useSnackbar();

    const getUsers = async () => {
        const response = await handleGet('users');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setUsers(data);
        setIsLoading(false);
    };

    const handleCloseDialog = () => setDialog(null);

    // add user

    const handleAddUser = async user => {
        handleCloseDialog();
        setIsLoading(true);

        const response = await handlePost('users', user);

        if (response.ok) {
            getUsers();
        }
        else {
            // todo handle invalid user
            console.error(response.statusText);
            setIsLoading(false);
        }
    };

    // edit user

    const handleOpenEditUserDialog = user => {
        setSelectedUser(user);
        setDialog(dialogs.EDIT_USER);
    };

    const handleCloseEditUserDialog = () => {
        handleCloseDialog();
        setSelectedUser(null);
    };

    const handleEditUser = async user => {
        handleCloseEditUserDialog();
        setIsLoading(true);
        const response = await handlePut(`users/${selectedUser.id}`, user);

        if (response.status === 403) {
            showSnackbar('You cannot complete the action', 'error');
        }

        getUsers();
    };

    // delete user

    const handleOpenDeleteUserDialog = user => {
        setSelectedUser(user);
        setDialog(dialogs.DELETE_USER);
    };

    const handleCloseDeleteUserDialog = () => {
        handleCloseDialog();
        setSelectedUser(null);
    };

    const handleDeleteUser = async () => {
        handleCloseDeleteUserDialog();
        setIsLoading(true);
        await handleDelete(`users/${selectedUser.id}`);
        getUsers();
    };

    const handleSendInvitation = async user => {
        setIsLoading(true);

        const response = await handlePut(`users/${user.id}`, { sendInvitation: true });

        if (response.ok) {
            await getUsers();
        }

        setIsLoading(false);
    };

    const hasUserBeenReinvitedRecently = user => {
        if (!user.invitedDateTime) {
            return false;
        }

        const inviteSentDateTime = new Date(user.invitedDateTime);

        return (inviteSentDateTime.getTime() + 300000) > Date.now();
    };

    useEffect(() => {
        getUsers();
    }, []);

    const UsersTable = () => {
        if (users.length > 0) {
            return (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row">Email Address</TableCell>
                                <TableCell component="th" scope="row">Name</TableCell>
                                <TableCell component="th" scope="row">Added</TableCell>
                                <TableCell component="th" scope="row">Last Logged In</TableCell>
                                <TableCell component="th" scope="row">Permissions</TableCell>
                                <TableCell component="th" scope="row" align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => {
                                const disabled = hasUserBeenReinvitedRecently(user);

                                return (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.emailAddress}</TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{new Date(user.createdDateTime).toLocaleString('en-GB')}</TableCell>
                                        <TableCell>
                                            {user.lastLoggedIn ? new Date(user.lastLoggedIn).toLocaleString('en-GB') : (
                                                <Tooltip title={disabled ? 'Please wait 5 minutes and refresh the page before trying again' : 'Resend invitation'}>
                                                    <div>
                                                        Never
                                                        <Button
                                                            sx={{ marginLeft: 1 }}
                                                            onClick={() => handleSendInvitation(user)}
                                                            size="small"
                                                            disabled={disabled}
                                                        >
                                                            Resend Invitation
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>{user.permissions.length > 0 ? user.permissions.map(({ name }) => name).join(', ') : 'None'}</TableCell>
                                        <TableCell align="right">
                                            {user.emailAddress !== emailAddress && (
                                                <>
                                                    <IconButton
                                                        onClick={() => handleOpenEditUserDialog(user)}
                                                        size="small"
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>

                                                    <IconButton
                                                        disabled={users.length <= 1}
                                                        onClick={() => handleOpenDeleteUserDialog(user)}
                                                        size="small"
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }

        return (
            <Typography sx={{ p: 2 }}>
                You have no users in your account.
            </Typography>
        );
    };

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    const availableSeats = Math.max(userLimit - users.length, 0);

    return (
        <>
            <TitleBar
                title="Users"
                actions={(
                    <Button
                        disabled={availableSeats === 0}
                        onClick={() => setDialog(dialogs.ADD_FIELD)}
                    >
                        Invite User
                    </Button>
                )}
            />

            {availableSeats > 0 ? (
                <AlertBar severity="info" positionTop={128}>
                    You have <strong>{availableSeats}</strong> {availableSeats === 1 ? 'user' : 'users'} remaining.
                </AlertBar>
            ) : (
                <AlertBar severity="warning" positionTop={128}>
                    You have reached your user limit - contact support on <strong><a href="tel:01392447200">01392 447 200</a></strong> if you need more.
                </AlertBar>
            )}
            <Divider />

            <Box sx={{ padding: 2 }}>
                <Paper>
                    <UsersTable />
                </Paper>

                {dialog === dialogs.ADD_FIELD && (
                    <InviteUserDialog
                        onSubmit={handleAddUser}
                        onClose={handleCloseDialog}
                    />
                )}

                {(selectedUser && dialog === dialogs.EDIT_USER) && (
                    <EditUserDialog
                        user={selectedUser}
                        onSubmit={handleEditUser}
                        onClose={handleCloseEditUserDialog}
                    />
                )}
                {(selectedUser && dialog === dialogs.DELETE_USER) && (
                    <DeleteUserDialog
                        user={selectedUser}
                        onSubmit={handleDeleteUser}
                        onClose={handleCloseDeleteUserDialog}
                    />
                )}
            </Box>
        </>
    );
};

export default Users;