import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';

const TabPanel = ({ value, index, children }) => {
    return (
        <div>
            {value === index && children}
        </div>
    );
};

const CreateKeyDialog = ({ open, onSubmit, onClose }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        !open && setName('');
    }, [open]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Add API Key</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create a new key to access NewZapp's API.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    required
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit(name)}>Add Key</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const RevokeKeyDialog = ({ keyName, open, onConfirm, onClose }) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Revoke API Key</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to revoke the key {keyName}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>Revoke Key</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const KeysTab = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [keys, setKeys] = useState([]);
    const [isCreateKeyDialogOpen, setIsCreateKeyDialogOpen] = useState(false);
    const [isRevokeKeyDialogOpen, setIsRevokeKeyDialogOpen] = useState(false);
    const [keyToRevoke, setKeyToRevoke] = useState(null);
    const { handleGet, handlePut, handlePost } = useApi();

    const handleLoadKeys = async () => {
        const response = await handleGet('account/api-keys');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();
        setKeys(data);
        setIsLoading(false);
    };

    const handleCreateKey = async name => {
        setIsCreateKeyDialogOpen(false);

        await handlePost('account/api-keys', { name });

        handleLoadKeys();
    };

    const handleShowRevokeKeyDialog = key => {
        setKeyToRevoke(key);
        setIsRevokeKeyDialogOpen(true);
    };

    const handleHideRevokeKeyDialog = () => {
        setIsRevokeKeyDialogOpen(false);
        setKeyToRevoke(null);
    };

    const handleRevokeKey = async () => {
        const id = keyToRevoke.id;

        handleHideRevokeKeyDialog();

        await handlePut(`account/api-keys/${id}`, { enabled: false });

        handleLoadKeys();
    };

    useEffect(() => {
        handleLoadKeys();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <div>
            <Typography>The NewZapp API makes it easy for programmers to integrate many of NewZapp’s features into other applications. API Keys provide full access to your NewZapp account, so keep them secure.</Typography>

            <Toolbar disableGutters>
                <Button onClick={() => setIsCreateKeyDialogOpen(true)}>Create API Key</Button>
            </Toolbar>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">Name</TableCell>
                            <TableCell component="th" scope="row">Created Date/Time</TableCell>
                            <TableCell component="th" scope="row">Key</TableCell>
                            <TableCell component="th" scope="row" align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keys.map(key => (
                            <TableRow key={key.id}>
                                <TableCell>{key.name}</TableCell>
                                <TableCell>{key.createdDateTime ? new Date(key.createdDateTime).toLocaleString('en-GB') : '-'}</TableCell>
                                <TableCell>{key.key}</TableCell>
                                <TableCell align="right">{key.enabled ? <Link onClick={() => handleShowRevokeKeyDialog(key)}>Revoke</Link> : 'Revoked'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateKeyDialog open={isCreateKeyDialogOpen} onClose={() => setIsCreateKeyDialogOpen(false)} onSubmit={handleCreateKey} />
            {keyToRevoke && <RevokeKeyDialog keyName={keyToRevoke.name} open={isRevokeKeyDialogOpen} onClose={handleHideRevokeKeyDialog} onConfirm={handleRevokeKey} />}
        </div>
    );
};

const UsageChart = () => {
    const [usage, setUsage] = useState();
    const canvas = useRef();
    const { handleGet } = useApi();

    const handleBuildChart = () => {

        const data = {
            labels: usage.map(u => u.label),
            datasets: [{
                data: usage.map(u => u.total)
            }]
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                }
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        precision: 0
                    }
                }
            }
        };

        new Chart(canvas.current, {
            type: 'line',
            data,
            options
        });
    };

    const handleLoadUsage = async () => {
        const response = await handleGet('account/api-requests/by-day');
        const data = await response.json();
        setUsage(data);
    };

    useEffect(() => {
        handleLoadUsage();
    }, []);

    useEffect(() => {
        usage && handleBuildChart();
    }, [usage]);

    return (
        <Paper sx={{ padding: 3, height: 300, marginBottom: 2 }}>
            <canvas ref={canvas} />
        </Paper>
    );
};

const RequestsTable = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [requests, setRequests] = useState();
    const { handleGet } = useApi();

    const handleLoadRequests = async () => {
        const response = await handleGet('account/api-requests');
        const data = await response.json();
        setRequests(data);
        setIsLoading(false);
    };

    useEffect(() => {
        handleLoadRequests();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component="th" scope="row">Start Date/Time</TableCell>
                        <TableCell component="th" scope="row">IP Address</TableCell>
                        <TableCell component="th" scope="row">Resource</TableCell>
                        <TableCell component="th" scope="row" align="right">Duration</TableCell>
                        <TableCell component="th" scope="row" align="right">Response</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requests.map(request => (
                        <TableRow key={request.id}>
                            <TableCell>{new Date(request.dateTime).toLocaleString('en-GB')}</TableCell>
                            <TableCell>{request.ipAddress}</TableCell>
                            <TableCell>{request.resource}</TableCell>
                            <TableCell align="right">{request.duration}</TableCell>
                            <TableCell align="right">{request.responseCode}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const HistoryTab = () => {
    return (
        <div>
            <UsageChart />
            <RequestsTable />
        </div>
    );
};

const ApiSettings = () => {
    const [tab, setTab] = useState(0);

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{ top: 64 }}
            >
                <Toolbar>
                    <Typography component="h1" variant="h6">API Integration</Typography>
                </Toolbar>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                    <Tab label="API Keys" />
                    <Tab label="Application Call History" />
                </Tabs>
            </AppBar>

            <Box sx={{ padding: 2 }}>
                <TabPanel value={tab} index={0}>
                    <KeysTab />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <HistoryTab />
                </TabPanel>
            </Box>
        </>
    );
};

export default ApiSettings;