import React, { useEffect, useState } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import useTheme from '@mui/material/styles/useTheme';
import { useHistory, useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import useSnackbar from '../hooks/useSnackbar';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import StripoEditor from '../stripoEditor/StripoEditor';
import ContentInUseDialog from './dialogs/ContentInUseDialog';
import useEditorHubConnection from './hooks/useEditorHubConnection';
import Audience from './views/Audience';
import Proof from './views/Proof';
import AssignQRCode from './views/AssignQRCode';
import Schedule from './views/Schedule';
import Send from './views/Send';
import Setup from './views/Setup';

const drawerWidth = 300;

const steps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set your sending details and choose topics' },
    { name: 'Proof', description: 'Send a copy to trusted recipients (optional)' },
    { name: 'Audience', description: 'Choose the recipients for this campaign' },
    { name: 'Schedule', description: 'Send now or any time in the future' },
    { name: 'QR Code', description: 'Assign your campaign to a QR Code' },
    { name: 'Final Checks', description: 'Last chance to right any wrongs...' }
];

const Email = ({ emailStep = 0 }) => {
    const { id } = useParams();
    const history = useHistory();
    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(emailStep);
    const [previewHtml, setPreviewHtml] = useState('');
    const [campaign, setCampaign] = useState(null);
    const [schedule, setSchedule] = useState(false);
    const [sendDateTime, setSendDateTime] = useState(new Date(new Date().setHours(new Date().getHours() + 1)));
    const { showSnackbar } = useSnackbar();
    const [proofRecipients, setProofRecipients] = useState([]);
    const [isProofSent, setIsProofSent] = useState(false);
    const [audienceName, setAudienceName] = useState(null);
    const { handleGet, handlePut, handleDelete } = useApi();
    const theme = useTheme();
    const { connection, usersEditing, setUsersEditing } = useEditorHubConnection();
    const [qrCodeContent, setQRCodeContent] = useState(null);

    const titleBarDepth = theme.zIndex.drawer + 1;

    const [audience, setAudience] = useState({
        audienceType: 2,
        emailAddresses: [],
        groupIds: [],
        selectedSegment: null
    });

    const handleLoadEmail = async () => {
        const response = await handleGet(`emails/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            history.push('/editor');
            return;
        }

        const data = await response.json();
        setEmail(data);
    };

    const handleFetchEmailTopics = async () => {
        const response = await handleGet(`emails/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const { topics } = await response.json();
        setEmail({ ...email, topics });
    };

    const handleLoad = async () => {
        await handleLoadEmail();
        setIsLoading(false);
    };

    const handleUpdateEmail = async () => {
        const response = await handlePut(`emails/${email.id}`, email);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        step === 0 && showSnackbar('Changes saved', 'success');
    };

    const handleDeleteQRCodeContent = async (qrCodeId, qrCodeContentId) => {
        const response = await handleDelete(`qrcodes/${qrCodeId}/content/${qrCodeContentId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        //fetch campaign only for qrcodes
        const campaignResponse = await handleGet(`campaigns/${id}`);

        if (!campaignResponse.ok) {
            console.error(response?.error);
            return;
        }

        const { qrCodes } = await campaignResponse.json();

        setCampaign({ ...campaign, qrCodes });
    };

    const handleCompleteEdit = previewHtml => {
        setPreviewHtml(previewHtml);
        setStep(1);
    };

    const handleCompleteSetup = async (newCampaign, step) => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(step);
    };

    const handleCompleteAudience = newCampaign => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(4);
    };

    const handleCompleteSchedule = newCampaign => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(5);
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        handleUpdateEmail();
    }, [email]);

    const handleGetAudienceName = audience => {
        switch (audience) {
            case 0:
                return 'Manual List';
            case 1:
                return 'Proof Group';
            case 2:
                return `${campaign?.groupIds?.length} Group${campaign?.groupIds?.length > 1 ? 's' : ''}`;
            case 3:
                return 'Segment';
            case 4:
                return 'All Contacts';
            default:
                return null;
        }
    };

    const requestEditorAccess = async () => {
        try {
            await connection.start().then(() => {
                connection.invoke('RequestEditorAccess', parseInt(id));
            }).then(() => {
                connection.invoke('GetEditorsInUseByEmail', parseInt(id));
            });
            connection.on('GetUsersEditing', editorsInUse => {
                setUsersEditing(editorsInUse);
            });
        }
        catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setAudienceName(handleGetAudienceName(campaign?.audience));
    }, [campaign?.audience]);

    useEffect(() => {
        if (connection && connection.state !== HubConnectionState.Connected) {
            requestEditorAccess();
        }
    }, [connection]);

    useEffect(() => {
        handleLoad();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            {!!usersEditing.length && <ContentInUseDialog usersEditing={usersEditing} />}
            {step === 0 ? (
                <StripoEditor
                    email={email}
                    onUpdateEmail={props => setEmail({ ...email, ...props })}
                    onNext={handleCompleteEdit}
                />
            ) : (
                <>
                    {step === 1 && (
                        <Setup
                            email={email}
                            onUpdateEmail={props => setEmail({ ...email, ...props })}
                            onNext={campaign => handleCompleteSetup(campaign, 2)}
                            onBack={campaign => handleCompleteSetup(campaign, 0)}
                            onFetchEmailTopics={handleFetchEmailTopics}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            audienceName={audienceName}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                        />
                    )}
                    {step === 2 && (
                        <Proof
                            email={email}
                            html={previewHtml}
                            onNext={() => setStep(3)}
                            onBack={() => setStep(1)}
                            emailAddresses={proofRecipients}
                            setEmailAddresses={setProofRecipients}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            setIsProofSent={setIsProofSent}
                            audienceName={audienceName}
                            titleBarDepth={titleBarDepth}
                            onNavigateToEditor={() => setStep(0)}
                            setStep={setStep}
                        />
                    )}
                    {step === 3 && (
                        <Audience
                            audience={audience}
                            onChangeAudience={props => setAudience({ ...audience, ...props })}
                            onNext={handleCompleteAudience}
                            onBack={() => setStep(2)}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            audienceName={audienceName}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                        />
                    )}
                    {step === 4 && (
                        <Schedule
                            schedule={schedule}
                            onToggleSchedule={setSchedule}
                            sendDateTime={sendDateTime}
                            onChangeSendDateTime={setSendDateTime}
                            onNext={handleCompleteSchedule}
                            onBack={() => setStep(3)}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            titleBarDepth={titleBarDepth}
                            audienceName={audienceName}
                            setStep={setStep}
                        />
                    )}
                    {step === 5 && (
                        <AssignQRCode
                            qrCodeContent={qrCodeContent}
                            setQRCodeContent={setQRCodeContent}
                            onNext={() => setStep(6)}
                            onBack={() => setStep(4)}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            audienceName={audienceName}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                            defaultStartDate={schedule ? sendDateTime : null}
                            onDeleteQRCodeContent={handleDeleteQRCodeContent}
                            contentType="Email"
                        />
                    )}
                    {step === 6 && (
                        <Send
                            emailId={email.id}
                            topics={email.topics}
                            campaign={campaign}
                            previewHtml={previewHtml}
                            onBack={() => setStep(5)}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            isProofSent={isProofSent}
                            titleBarDepth={titleBarDepth}
                            audienceName={audienceName}
                            qrCodeContent={qrCodeContent}
                            setStep={setStep}
                            type={email.type}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Email;