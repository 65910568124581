import React, { forwardRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Links from './views/Links';
import Hotspot from './views/Hotspot';
import { format } from 'date-fns';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';
import { printStyleSection, printStyleCover, printStyleBreakAfter, printStyleOverrides } from '../theme/constants';

const titleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const appBarStyle = {
    top: 64,
    '@media print': {
        position: 'relative',
        top: 0,
        display: 'block',
        boxShadow: 'none',
        borderBottom: '1px solid #EEE'
    }
};

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Page = () => {
    const { campaignId } = useParams();
    const { handleGet } = useApi();
    const { company, logo } = useAccount();

    const [campaign, setCampaign] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [tab, setTab] = useState(0);
    const [linkId, setLinkId] = useState(null);
    const [showPrintDialog, setShowPrintDialog] = useState(false);

    const publishedDate = campaign ? format((new Date(campaign.sendDateTime)), 'dd-MM-yyyy HHmm') : '-';

    const handleLoadCampaign = async () => {
        const response = await handleGet(`reports/${campaignId}`);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        setCampaign(data);
    };

    const handleViewWhoClicked = linkId => {
        setLinkId(linkId);
    };

    const handleSetDocumentTitle = () => {
        document.title = `Landing Page Report for '${campaign?.subject}' - Published ${publishedDate}`;
    };

    const handleUnSetDocumentTitle = () => {
        document.title = 'NewZapp';
    };

    useEffect(() => {
        if (!isInitialising) {
            setIsLoading(false);
            setCampaign(null);
        }

        handleLoadCampaign();
    }, [campaignId]);

    useEffect(() => {
        setIsLoading(!Boolean(campaign));

        if (campaign) {
            setIsInitialising(false);
        }
    }, [campaign]);

    useEffect(() => {
        handleLoadCampaign();
    }, []);

    useEffect(() => {
        if (!campaign) {
            return;
        }

        window.addEventListener('beforeprint', () => {
            handleSetDocumentTitle();
        });

        window.addEventListener('afterprint', () => {
            handleUnSetDocumentTitle();
        });

        return () => {
            window.removeEventListener('beforeprint', () => {
                handleSetDocumentTitle();
            });

            window.removeEventListener('afterprint', () => {
                handleUnSetDocumentTitle();
            });
        };
    }, [campaign]);

    useEffect(() => {
        if (!isInitialising && linkId) {
            setTab(1);
        }
    }, [linkId]);

    if (isLoading || isInitialising || !campaign) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={appBarStyle}
            >
                <Toolbar variant="dense" sx={{ overflow: 'hidden', pt: 2 }}>
                    <Stack>
                        <Typography component="h1" variant="h6" sx={titleStyle}>{campaign.name}</Typography>
                        <Typography component="h2" variant="body1" sx={titleStyle}>Title: {campaign.subject}</Typography>
                    </Stack>
                </Toolbar>
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Typography component="h2" variant="subtitle1" sx={titleStyle}>
                            Published: {format((new Date(campaign.sendDateTime)), 'dd/MM/yyyy HH:mm')}
                        </Typography>
                    </Box>
                    <Box sx={{ whiteSpace: 'nowrap', paddingLeft: 2 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography>Status: <Chip component="span" color={campaign.published ? 'success' : undefined} label={campaign.published ? 'Published' : 'Draft'} /></Typography>
                            <Typography>Total Opens: {campaign.totalShareLinkOpens}</Typography>
                            <Typography>Total Clicks: {campaign.totalShareLinkClicks}</Typography>
                            <Button
                                href={campaign.shareLink}
                                target="_blank"
                                endIcon={<OpenInNewIcon />}
                                disabled={!campaign.published}
                            >
                                Open Page
                            </Button>
                        </Stack>
                    </Box>
                </Toolbar>
                <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                    <Tab label="Hotspot" />
                    <Tab label="Links" />
                    <Tab label="Print" />
                </Tabs>
            </AppBar>
            <Box sx={{ overflow: 'hidden' }}>
                {tab === 0 && (
                    <Hotspot
                        campaignId={campaign.id}
                        onViewWhoClicked={handleViewWhoClicked}
                        type="page"
                    />
                )}
                {tab === 1 && (
                    <Links
                        campaignId={campaign.id}
                        linkId={linkId}
                        type="page"
                    />
                )}
                {tab === 2 && (
                    <>
                        <Container maxWidth="lg" sx={{ marginTop: 3, paddingBottom: 3 }}>
                            <Paper sx={{ padding: 3 }}>
                                <Alert severity="info">
                                    <AlertTitle>Printing guidance</AlertTitle>
                                    <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                                        For the best visual experience, turn on 'Background graphics' in your browser's print dialog.
                                    </Typography>
                                    <Typography variant="body2">
                                        In Chrome and Edge, this option can be found in 'More settings'. In Firefox and Safari this option is called 'Print backgrounds'.
                                    </Typography>
                                </Alert>
                                <Button
                                    onClick={() => setShowPrintDialog(true)}
                                    color="secondary"
                                    sx={{ marginTop: 2 }}
                                >
                                    Generate Manager's Report
                                </Button>
                            </Paper>
                        </Container>
                        <Dialog
                            fullScreen
                            open={showPrintDialog}
                            onClose={() => setShowPrintDialog(false)}
                            TransitionComponent={Transition}
                            sx={printStyleOverrides}
                        >
                            <AppBar sx={{ position: 'relative', displayPrint: 'none' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => setShowPrintDialog(false)}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        Manager's Report: {campaign.subject}
                                    </Typography>
                                    <Button onClick={() => window.print()}>
                                        Print
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <Paper elevation={0} square>
                                <Container maxWidth="lg">
                                    <Box sx={{ ...printStyleCover, ...printStyleBreakAfter }}>
                                        <Box sx={{ paddingBottom: 4 }}>
                                            {logo ? (
                                                <img src={logo} style={{ maxWidth: 400, height: 'auto' }} />
                                            ) : (
                                                <Typography
                                                    variant="h3"
                                                >
                                                    {company}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Typography
                                            variant="h2"
                                        >
                                            Landing Page Report
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ paddingBottom: 0, paddingTop: 2 }}
                                        >
                                            {campaign.name}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ paddingBottom: 2, paddingTop: 0 }}
                                        >
                                            Title: {campaign.subject}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ paddingBottom: 1, paddingTop: 1 }}
                                        >
                                            Total Opens: {campaign.totalShareLinkOpens}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ paddingBottom: 1, paddingTop: 1 }}
                                        >
                                            Total Clicks: {campaign.totalShareLinkClicks}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                        >
                                            Published {format((new Date(campaign.sendDateTime)), 'dd/MM/yyyy HH:mm')}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                        >
                                            Report created: {format((new Date()), 'dd/MM/yyyy HH:mm')}
                                        </Typography>
                                    </Box>
                                    <Box sx={printStyleSection}>
                                        <Typography
                                            variant="h5"
                                        >
                                            Hotspot
                                        </Typography>
                                        <Hotspot
                                            campaignId={campaign.id}
                                        />
                                    </Box>
                                    <Box sx={printStyleSection}>
                                        <Typography
                                            variant="h5"
                                        >
                                            Link summary table
                                        </Typography>
                                        <Links
                                            campaignId={campaign.id}
                                            linkId={null}
                                            parentIsLoading={isLoading}
                                            setParentIsLoading={isLoading => setIsLoading(isLoading)}
                                        />
                                    </Box>
                                </Container>
                            </Paper>
                        </Dialog>
                    </>
                )}
            </Box>
        </>
    );
};

export default Page;