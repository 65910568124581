import React, { cloneElement, useState } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EmailIcon from '@mui/icons-material/Email';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TopicIcon from '@mui/icons-material/Topic';
import IconButton from '@mui/material/IconButton';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import WebIcon from '@mui/icons-material/Web';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import FullScreenSettingsDialog from '../shared/FullScreenSettingsDialog';
import Topics from '../account/topics/Topics';
import CustomFields from '../account/customFields/CustomFields';
import BrandManagement from '../account/brandManagement/BrandManagement';
import useAccount from '../hooks/useAccount';
import Documents from '../documents/Documents';

const ElevationScroll = props => {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0
    });
};

const Settings = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const open = Boolean(anchorEl);
    const { user } = useAccount();

    const hasManageBrandPermission = user.permissions.includes('Manage Brand');

    const menuItems = {
        manageTopics: { label: 'Manage Topics', icon: <TopicIcon fontSize="small" />, component: <Topics /> },
        customFields: { label: 'Custom Fields', icon: <EditAttributesIcon fontSize="small" />, component: <CustomFields /> },
        ...(hasManageBrandPermission && {
            brandManagement: { label: 'Brand Management', icon: <WebIcon fontSize="small" />, component: <BrandManagement /> }
        })
    };

    const handleDialogOpen = (menuItem) => {
        setIsDialogOpen(true);
        setSelectedMenuItem(menuItem);
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setAnchorEl(null);
        setSelectedMenuItem(null);
    };

    return (
        <>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="text"
            >
                <SettingsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {Object.keys(menuItems).map((menuItem) => (
                    <MenuItem key={menuItem} onClick={() => handleDialogOpen(menuItem)}>
                        <ListItemIcon>
                            {menuItems[menuItem].icon}
                        </ListItemIcon>
                        <ListItemText>{menuItems[menuItem].label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
            {isDialogOpen && (
                <FullScreenSettingsDialog handleDialogClose={handleDialogClose} title={menuItems[selectedMenuItem]?.label}>
                    {menuItems[selectedMenuItem]?.component}
                </FullScreenSettingsDialog>
            )}
        </>
    );
};

const ContentNavigation = ({ type, onSetType }) => {
    const [documentDialog, setDocumentDialog] = useState(false);

    return (
        <>
            <ElevationScroll>
                <AppBar
                    position="sticky"
                    color="inherit"
                    sx={{ top: 64, display: 'flex', flexDirection: 'row' }}
                >
                    <Tabs value={type} onChange={(e, value) => onSetType(value)} sx={{ flexGrow: 1 }}>
                        <Tab icon={<EmailIcon />} iconPosition="start" label="Emails" value="email" />
                        <Tab icon={<WebIcon />} iconPosition="start" label="Landing Pages" value="page" />
                        <Tab icon={<AnalyticsIcon />} iconPosition="start" label="Surveys" value="survey" />
                        <Tab icon={<QrCode2Icon />} iconPosition="start" label="QR Codes" value="qrcode" />
                    </Tabs>
                    <Box sx={{ px: 3, mt: 2 }}>
                        <Button sx={{ mr: 1 }} variant="contained" onClick={() => setDocumentDialog(true)}>Manage Documents</Button>
                        <Settings />
                    </Box>
                </AppBar>
            </ElevationScroll>
            <Divider />
            {documentDialog && (
                <FullScreenSettingsDialog handleDialogClose={() => setDocumentDialog(false)} title="Manage Documents">
                    <Documents />
                </FullScreenSettingsDialog>
            )}
        </>
    );
};

export default ContentNavigation;
