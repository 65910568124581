import React, { forwardRef, useReducer } from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Hotspot from '../views/Hotspot';
import Summary from '../views/Summary';
import Social from '../views/Social';
import Device from '../views/Device';
import CampaignHeatmap from '../CampaignHeatmap';
import PrintReportTable from '../PrintReportTable';
import Links from '../views/Links';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import LinkIcon from '@mui/icons-material/Link';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import DevicesIcon from '@mui/icons-material/Devices';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShareIcon from '@mui/icons-material/Share';
import { format } from 'date-fns';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useAccount from '../../hooks/useAccount';
import { printStyleSection, printStyleCover, printStyleBreakAfter, printStyleOverrides } from '../../theme/constants';

const numberOfContactsAvailable = [10, 25, 50];

const reducer = (prevState, newState) => {

    let { showLoadingOverlay, openDialog, showHotspot, showLinks, showHeatmap, showSocial, showData, showDevice, loadingSummary, loadingLinks, loadingDataOpens, loadingDataClicks, loadingHeatmap, loadingSocial, loadingDevice, numberOfContacts } = { ...prevState, ...newState };

    if (openDialog !== prevState.openDialog) {
        //handle user closing dialog and repicking options
        if (!openDialog) {
            loadingLinks = true;
            loadingDataOpens = true;
            loadingDataClicks = true;
            loadingHeatmap = true;
            loadingSocial = true;
            loadingDevice = true;
            loadingSummary = true;

            showLoadingOverlay = true;
        }
    }

    //only wait for items to load if that item is chosen to be shown
    const linksComplete = showLinks ? !loadingLinks ? true : false : true;
    const dataClicksComplete = showData ? !loadingDataClicks ? true : false : true;
    const dataOpensComplete = showData ? !loadingDataOpens ? true : false : true;
    const heatmapComplete = showHeatmap ? !loadingHeatmap ? true : false : true;
    const socialComplete = showSocial ? !loadingSocial ? true : false : true;
    const deviceComplete = showDevice ? !loadingDevice ? true : false : true;

    //If all optional and non-optional items are complete, we're ready
    if (!loadingSummary && linksComplete && dataOpensComplete && dataClicksComplete && heatmapComplete && socialComplete && deviceComplete) {
        showLoadingOverlay = false;
    }

    return {
        showLoadingOverlay,
        openDialog,

        showHotspot,
        showLinks,
        showHeatmap,
        showSocial,
        showData,
        showDevice,

        loadingSummary,
        loadingLinks,
        loadingDataOpens,
        loadingDataClicks,
        loadingHeatmap,
        loadingSocial,
        loadingDevice,

        numberOfContacts
    };
};

const initialState = {
    showLoadingOverlay: true,
    openDialog: false,

    showHotspot: true,
    showLinks: true,
    showHeatmap: true,
    showSocial: true,
    showData: true,
    showDevice: true,

    loadingSummary: true,
    loadingLinks: true,
    loadingDataOpens: true,
    loadingDataClicks: true,
    loadingHeatmap: true,
    loadingSocial: true,
    loadingDevice: true,

    numberOfContacts: numberOfContactsAvailable[0]
};

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Print = ({ campaign }) => {
    const [state, setState] = useReducer(reducer, initialState);
    const { company, logo } = useAccount();

    const handleCloseDialog = () => setState({ openDialog: false });

    return (
        <>
            <Box padding={3}>
                <Paper sx={{ marginTop: 3, px: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <ModeStandbyIcon />
                                    </ListItemIcon>
                                    <ListItemText id="hotspots" primary="Email view with hotspots" />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showHotspot: e.target.checked })}
                                        checked={state.showHotspot}
                                        inputProps={{
                                            'aria-labelledby': 'hotspots'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <LinkIcon />
                                    </ListItemIcon>
                                    <ListItemText id="links" primary="Link summary table" />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showLinks: e.target.checked })}
                                        checked={state.showLinks}
                                        inputProps={{
                                            'aria-labelledby': 'links'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ThermostatIcon />
                                    </ListItemIcon>
                                    <ListItemText id="heatmap" primary="Peak engagement heatmap" />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showHeatmap: e.target.checked })}
                                        checked={state.showHeatmap}
                                        inputProps={{
                                            'aria-labelledby': 'heatmap'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ShareIcon />
                                    </ListItemIcon>
                                    <ListItemText id="social" primary="Social summary" />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showSocial: e.target.checked })}
                                        checked={state.showSocial}
                                        inputProps={{
                                            'aria-labelledby': 'social'
                                        }}
                                    />
                                </ListItem>
                                <ListItem sx={{ border: '1px solid #eee' }}>
                                    <ListItemIcon>
                                        <PieChartIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        id="data"
                                        primary="Data"
                                        secondary={(
                                            <React.Fragment>
                                                <FormControl size="small" sx={{ display: 'block' }}>
                                                    <InputLabel id="data-count-label">Number of Contacts</InputLabel>
                                                    <Select
                                                        labelId="data-count-label"
                                                        id="data-count"
                                                        value={state.numberOfContacts}
                                                        label="Number of Contacts"
                                                        onChange={event => setState({ numberOfContacts: event.target.value })}
                                                        sx={{ display: 'block' }}
                                                    >
                                                        {numberOfContactsAvailable.map((e, i) => <MenuItem key={i} value={e}>{e}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </React.Fragment>
                                        )}
                                    />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showData: e.target.checked })}
                                        checked={state.showData}
                                        inputProps={{
                                            'aria-labelledby': 'data'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <DevicesIcon />
                                    </ListItemIcon>
                                    <ListItemText id="device" primary="Device and Mail Apps" />
                                    <Switch
                                        edge="end"
                                        onChange={e => setState({ showDevice: e.target.checked })}
                                        checked={state.showDevice}
                                        inputProps={{
                                            'aria-labelledby': 'device'
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={8} alignItems="center">
                            <Alert severity="info">
                                <AlertTitle>Printing guidance</AlertTitle>
                                <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                                    For the best visual experience, turn on 'Background graphics' in your browser's print dialog.
                                </Typography>
                                <Typography variant="body2">
                                    In Chrome and Edge, this option can be found in 'More settings'. In Firefox and Safari this option is called 'Print backgrounds'.
                                </Typography>
                            </Alert>
                            <Button
                                onClick={() => setState({ openDialog: true })}
                                color="secondary"
                                sx={{ marginTop: 2 }}
                            >
                                Generate Manager's Report
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Dialog
                fullScreen
                open={state.openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
                sx={printStyleOverrides}
            >
                <AppBar sx={{ position: 'relative', displayPrint: 'none' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Manager's Report: {campaign.subject}
                        </Typography>
                        <Button onClick={() => window.print()}>
                            Print
                        </Button>
                    </Toolbar>
                </AppBar>
                <Paper elevation={0} square>
                    <Container maxWidth="lg">
                        <Box sx={{ ...printStyleCover, ...printStyleBreakAfter }}>
                            <Box sx={{ paddingBottom: 4 }}>
                                {logo ? (
                                    <img src={logo} style={{ maxWidth: 400, height: 'auto' }} />
                                ) : (
                                    <Typography
                                        variant="h3"
                                    >
                                        {company}
                                    </Typography>
                                )}
                            </Box>
                            <Typography
                                variant="h2"
                            >
                                Email Marketing Report
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ paddingBottom: 0, paddingTop: 2 }}
                            >
                                {campaign.name}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ paddingBottom: 2, paddingTop: 0 }}
                            >
                                Subject: {campaign.subject}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                Sent {format((new Date(campaign.sendDateTime)), 'dd/MM/yyyy HH:mm')}
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                Report created: {format((new Date()), 'dd/MM/yyyy HH:mm')}
                            </Typography>
                        </Box>
                        <Box sx={printStyleSection}>
                            <Typography
                                variant="h5"
                            >
                                Summary
                            </Typography>
                            <Summary
                                campaign={campaign}
                                isTabSelected={true}
                                isReport={true}
                                parentIsLoading={state.loadingSummary}
                                setParentIsLoading={loadingSummary => setState({ loadingSummary })}
                            />
                        </Box>
                        {state.showHotspot && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Hotspot
                                </Typography>
                                <Hotspot
                                    campaignId={campaign.id}
                                    onViewWhoClicked={null}
                                />
                            </Box>
                        )}
                        {state.showLinks && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Link summary table
                                </Typography>
                                <Links
                                    campaignId={campaign.id}
                                    onViewWhoClicked={null}
                                    linkId={null}
                                    parentIsLoading={state.loadingLinks}
                                    setParentIsLoading={loadingLinks => setState({ loadingLinks })}
                                />
                            </Box>
                        )}
                        {state.showData && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Data
                                </Typography>
                                <Typography
                                    variant="h6"
                                >
                                    Top {state.numberOfContacts} contacts ordered by Opens
                                </Typography>
                                <PrintReportTable
                                    campaignId={campaign.id}
                                    subscriberStatus="opened"
                                    parentIsLoading={state.loadingDataOpens}
                                    setParentIsLoading={loadingDataOpens => setState({ loadingDataOpens })}
                                    numberOfContacts={state.numberOfContacts}
                                />
                                <Typography
                                    variant="h6"
                                >
                                    Top {state.numberOfContacts} contacts ordered by Clicks
                                </Typography>
                                <PrintReportTable
                                    campaignId={campaign.id}
                                    subscriberStatus="clicked"
                                    parentIsLoading={state.loadingDataClicks}
                                    setParentIsLoading={loadingDataClicks => setState({ loadingDataClicks })}
                                    numberOfContacts={state.numberOfContacts}
                                />
                            </Box>
                        )}
                        {state.showHeatmap && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Peak engagement heatmap
                                </Typography>
                                <CampaignHeatmap
                                    campaignId={campaign.id}
                                    parentIsLoading={state.loadingHeatmap}
                                    setParentIsLoading={loadingHeatmap => setState({ loadingHeatmap })}
                                />
                            </Box>
                        )}
                        {state.showDevice && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Device and Mail Apps
                                </Typography>
                                <Device
                                    campaignId={campaign.id}
                                    parentIsLoading={state.loadingDevice}
                                    setParentIsLoading={loadingDevice => setState({ loadingDevice })}
                                    isReport={true}
                                />
                            </Box>
                        )}
                        {state.showSocial && (
                            <Box sx={printStyleSection}>
                                <Typography
                                    variant="h5"
                                >
                                    Social summary
                                </Typography>
                                <Social
                                    campaignId={campaign.id}
                                    campaignUniqueOpens={campaign.uniqueOpens}
                                    isReport={true}
                                    parentIsLoading={state.loadingSocial}
                                    setParentIsLoading={loadingSocial => setState({ loadingSocial })}
                                />
                            </Box>
                        )}
                    </Container>
                </Paper>
                {state.showLoadingOverlay && <LoadingOverlay />}
            </Dialog>
        </>
    );
};

export default Print;