import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import ShoppingList from '../../editor/ShoppingList';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import DateTimeSelector from '../selects/DateTimeSelector';
import QRCodes from '../tabs/QRCodes';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import QRCodeImage from '../../qrcode/QRCodeImage';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import isValidDate from '../../isValidDate';
import { lighten } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import TableBody from '@mui/material/TableBody';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';

const SelectedDates = ({ startDate, endDate }) => {
    return `${format((new Date(startDate)), 'HH:mm \'on\' MMMM do yyyy')}${endDate ? ` until ${isValidDate(new Date(endDate)) ? format((new Date(endDate)), 'HH:mm \'on\' MMMM do yyyy') : ''}` : ', indefinitely.'}`;
};

const AssignQRCode = ({ qrCodeContent, setQRCodeContent, onNext, onBack, drawerWidth, steps, step, campaign, isProofSent, audienceName, titleBarDepth, setStep, onDeleteQRCodeContent, defaultStartDate, contentType }) => {
    const theme = useTheme();
    const [selectedQRCode, setSelectedQRCode] = useState(null);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleStart, setScheduleStart] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const existingQrCodeContents = campaign.qrCodes?.filter(e => !e.endDate || new Date(e.endDate) > new Date());
    const qrCodeAlreadyAssigned = existingQrCodeContents?.find(e => e.qrCodeId === selectedQRCode?.id);

    const handleDeleteAssociation = async (qrCodeId, qrCodeContentId) => {
        setIsLoading(true);
        await onDeleteQRCodeContent(qrCodeId, qrCodeContentId);
        setIsLoading(false);
    };

    useEffect(() => {
        if (selectedQRCode) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [selectedQRCode]);

    useEffect(() => {
        setStartDate(scheduleStart ? defaultStartDate ?? new Date() : new Date());
    }, [scheduleStart]);

    useEffect(() => {
        if (!selectedQRCode) {
            setQRCodeContent(null);
        }
        else if (startDate !== null) {
            setQRCodeContent({
                qrCodeId: selectedQRCode.id,
                startDate: startDate,
                endDate: endDate,
                name: selectedQRCode.name
            });
        }
    }, [selectedQRCode, startDate, endDate]);

    useEffect(() => {
        //date validation to enable/disable the next button
        if (selectedQRCode) {
            if (scheduleStart) {
                const endDateIsValid = !endDate || (new Date(endDate) > new Date(startDate));

                if (defaultStartDate) {
                    const startDateIsValid = new Date(startDate) >= new Date(defaultStartDate);

                    setIsValid(startDateIsValid && endDateIsValid);
                }
                else {
                    //no defaultDate, so this is NOW or user input. Only thing that matters is if it's a valid datetime
                    setIsValid(isValidDate(startDate) && endDateIsValid);
                }
            }
            else {
                setIsValid(true);
            }
        }
    }, [selectedQRCode, scheduleStart, startDate, endDate]);

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64, zIndex: titleBarDepth }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">
                        QR Code
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginRight: drawerWidth, flexGrow: 1, height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
                <Container disableGutters>
                    <Paper sx={{ m: 2, p: 3 }}>
                        <Collapse in={Boolean(selectedQRCode)}>
                            <Paper elevation={0} sx={{ p: 2, mb: 1, backgroundColor: lighten(theme.palette.success.light, 0.9) }}>
                                <Toolbar dense disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <Grid container spacing={1} width="100%">
                                        <Grid xs="auto">
                                            {selectedQRCode && (
                                                <QRCodeImage qrCode={selectedQRCode} size={72} />
                                            )}
                                        </Grid>
                                        <Grid xs>
                                            <Typography variant="h6">Selected QR Code: {selectedQRCode?.name}</Typography>
                                            <Box display="inlineFlex" alignItems="center">
                                                <PlaceIcon fontSize="small" color={selectedQRCode?.location ? 'info' : 'disabled'} />
                                                <Typography variant="body2" ml={0.5}>{selectedQRCode?.location ?? 'No location set'}</Typography>
                                            </Box>
                                            <Box display="inlineFlex" alignItems="center">
                                                <DateRangeIcon fontSize="small" />
                                                <Typography variant="body2" ml={0.5}>Active period: {(startDate && isValidDate(new Date(startDate))) ? <SelectedDates startDate={startDate} endDate={endDate} /> : 'Not selected'}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        onClick={() => setSelectedQRCode(null)}
                                        startIcon={<CloseIcon />}
                                    >
                                        Deselect
                                    </Button>
                                </Toolbar>
                                <Stack spacing={2} mt={2}>
                                    <Box pl={1.5}>
                                        <FormGroup>
                                            <FormControlLabel
                                                label="Schedule"
                                                control={(
                                                    <Switch
                                                        checked={scheduleStart}
                                                        onChange={e => setScheduleStart(e.target.checked)}
                                                        inputProps={{ 'aria-label': 'Schedule' }}
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Box>
                                    <Collapse in={scheduleStart}>
                                        <Stack spacing={2}>
                                            <Typography variant="body2">Choose a time period for the QR Code to be active:</Typography>
                                            <Stack direction="row" spacing={2} mb={1}>
                                                <DateTimeSelector
                                                    label="Start Date"
                                                    value={startDate}
                                                    setValue={setStartDate}
                                                    helperText="Required"
                                                    minDateTime={defaultStartDate ? new Date(defaultStartDate) : new Date()}
                                                    disableError={true}
                                                    fullWidth={false}
                                                />
                                                <DateTimeSelector
                                                    label="End Date"
                                                    value={endDate}
                                                    setValue={setEndDate}
                                                    helperText="Optional"
                                                    minDateTime={new Date(startDate)}
                                                    fullWidth={false}
                                                />
                                            </Stack>
                                            {(defaultStartDate && startDate && isValidDate(new Date(startDate)) && new Date(startDate) < new Date(defaultStartDate)) && (
                                                <Alert severity="error" variant="outlined">
                                                    The Start Date must be after the campaign is scheduled to send ({format((new Date(defaultStartDate)), 'HH:mm \'on\' MMMM do yyyy')})
                                                </Alert>
                                            )}
                                        </Stack>
                                    </Collapse>
                                    {qrCodeAlreadyAssigned && (
                                        <Box>
                                            <Typography variant="subtitle2">Existing Scheduled Periods</Typography>
                                            <Typography variant="body2">This QR Code is scheduled to link to this {contentType} during the following periods:</Typography>
                                            <Paper sx={{ mt: 1 }} variant="outlined">
                                                <Table size="small">
                                                    <TableBody>
                                                        {existingQrCodeContents?.filter(e => e.qrCodeId === selectedQRCode?.id).map(e => (
                                                            <TableRow
                                                                key={e.id}
                                                                sx={{
                                                                    '&:last-child td': {
                                                                        borderBottom: 0
                                                                    }
                                                                }}
                                                            >
                                                                <TableCell><SelectedDates startDate={e.startDate} endDate={e.endDate} /></TableCell>
                                                                <TableCell width="2%" align="right">
                                                                    <Tooltip title="Delete Scheduled Dates">
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => handleDeleteAssociation(selectedQRCode?.id, e.id)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                        </Box>
                                    )}
                                </Stack>
                            </Paper>
                        </Collapse>
                        <QRCodes onSelect={qrCode => setSelectedQRCode(selectedQRCode?.id === qrCode.id ? null : qrCode)} selectedQRCode={selectedQRCode} assignedQrCodes={existingQrCodeContents} />
                    </Paper>
                </Container>
            </div>
            <ShoppingList
                drawerWidth={drawerWidth}
                stepNumber={step}
                steps={steps}
                onNext={onNext}
                onBack={onBack}
                campaign={campaign}
                isProofSent={isProofSent}
                isQRCodeSelected={!!qrCodeContent || existingQrCodeContents?.length > 0}
                audienceName={audienceName}
                setStep={setStep}
                disabled={!isValid}
            />
            {isLoading && <LoadingOverlay />}
        </>
    );
};

export default AssignQRCode;