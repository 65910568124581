import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useApi from '../../hooks/useApi';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import SmtpTestResultsDialog from '../smtpSettings/dialogs/SmtpTestResultsDialog';

const SmtpSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [settings, setSettings] = useState();
    const [smtpUsername, setSmtpUsername] = useState('');
    const [smtpPassword, setSmtpPassword] = useState('');
    const [isSmtpTestSubmitting, setIsSmtpTestSubmitting] = useState(false);
    const [smtpTestResult, setSmtpTestResult] = useState();
    const [smtpTestResultDialogIsOpen, setSmtpTestResultDialogIsOpen] = useState(false);
    const { handleGet, handlePut, handlePost } = useApi();

    const getSettings = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            setIsLoading(false);
            return;
        }

        const data = await response.json();

        setSettings(data);
        setSmtpUsername(data.smtpUsername);
        setIsLoading(false);
    };

    const onTestSmtpSettings = async () => {
        setIsSmtpTestSubmitting(true);

        const response = await handlePost('account/test-smtp-settings', { smtpUsername, smtpPassword });

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        setIsSmtpTestSubmitting(false);
        setSmtpTestResult(data);
        setSmtpTestResultDialogIsOpen(true);
    };

    const onSubmit = async e => {
        e.preventDefault();

        setIsSubmitting(true);

        await handlePut('account', { smtpUsername, smtpPassword });

        // refresh
        await getSettings();

        setIsSubmitting(false);
    };

    useEffect(() => {
        getSettings();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar position="sticky" color="inherit" sx={{ top: 64 }}>
                <Toolbar>
                    <Typography component="h1" variant="h6">SMTP Configuration</Typography>
                </Toolbar>
            </AppBar>
            <Container sx={{ padding: 2, marginLeft: 'unset' }} maxWidth="md">
                <form onSubmit={onSubmit} noValidate autoComplete="off">
                    <TextField value={settings.smtpHost} type="text" label="Host" disabled={true} fullWidth={true} />
                    <TextField value={settings.smtpPort} type="text" label="Port" disabled={true} fullWidth={true} />
                    <TextField value={smtpUsername} onChange={e => setSmtpUsername(e.target.value)} required type="email" label="Username" fullWidth={true} />
                    <TextField value={smtpPassword} onChange={e => setSmtpPassword(e.target.value)} required type="text" label="Change password" fullWidth={true} />

                    <Box mt={2}>
                        <Button onClick={onTestSmtpSettings}>
                            {isSmtpTestSubmitting ? 'Testing' : 'Test'}
                        </Button>
                        <Button type="submit" sx={{ marginLeft: 2 }}>Save</Button>
                    </Box>
                </form>
                {smtpTestResult && (
                    <SmtpTestResultsDialog
                        open={smtpTestResultDialogIsOpen}
                        onClose={() => setSmtpTestResultDialogIsOpen(false)}
                        succeeded={smtpTestResult.succeeded}
                        message={smtpTestResult.message}
                    />
                )}
                {(isSubmitting || isSmtpTestSubmitting) && (
                    <LoadingOverlay />
                )}
            </Container>
        </>
    );
};

export default SmtpSettings;