import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import StripoEditor from '../../stripoEditor/StripoEditor';
import Proof from '../../editor/views/Proof';
import Setup from '../../editor/views/Setup';
import useSnackbar from '../../hooks/useSnackbar';
import useApi from '../../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';
import PublishEmailDialog from '../shared/dialogs/PublishEmailDialog';

const drawerWidth = 300;

const steps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set your sending details and choose topics' },
    { name: 'Proof', description: 'Send a copy to trusted recipients (optional)' }
];

const EmailEditor = () => {
    const { id } = useParams();
    const history = useHistory();
    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [previewHtml, setPreviewHtml] = useState('');
    const [campaign, setCampaign] = useState(null);
    const { showSnackbar } = useSnackbar();
    const [proofRecipients, setProofRecipients] = useState([]);
    const [isProofSent, setIsProofSent] = useState(false);
    const { handleGet, handlePut } = useApi();
    const theme = useTheme();
    const [showPublishEmailDialog, setShowPublishEmailDialog] = useState(false);

    const titleBarDepth = theme.zIndex.drawer + 1;

    const handleLoadEmail = async () => {
        const response = await handleGet(`superadmin/emails/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            history.push('/superadmin/emails');
            return;
        }

        const data = await response.json();
        setEmail(data);
    };

    const handleFetchEmailTopics = async () => {
        const response = await handleGet(`superadmin/emails/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const { topics } = await response.json();
        setEmail({ ...email, topics });
    };

    const handleLoad = async () => {
        await handleLoadEmail();
        setIsLoading(false);
    };

    const handleUpdateEmail = async (fields = {}) => {
        const response = await handlePut(`superadmin/emails/${email.id}`, { ...email, ...fields });

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        step === 0 && showSnackbar('Changes saved', 'success');
    };

    const handleCompleteEdit = previewHtml => {
        setPreviewHtml(previewHtml);
        setStep(1);
    };

    const handleCompleteSetup = async (newCampaign, step) => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(step);
    };

    const handlePublishEmail = async () => {
        setShowPublishEmailDialog(false);
        setIsLoading(true);

        await handleUpdateEmail({ hidden: false });

        history.push('/superadmin/content');
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        handleUpdateEmail();
    }, [email]);

    useEffect(() => {
        handleLoad();
    }, []);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    return (
        <>
            {step === 0 ? (
                <>
                    <StripoEditor
                        email={email}
                        onUpdateEmail={props => setEmail({ ...email, ...props })}
                        onNext={handleCompleteEdit}
                        title={`${email.customerId}: ${email.name}`}
                        showAlert={true}
                    />
                </>
            ) : (
                <>
                    {step === 1 && (
                        <Setup
                            email={email}
                            onUpdateEmail={props => setEmail({ ...email, ...props })}
                            onNext={campaign => handleCompleteSetup(campaign, 2)}
                            onBack={campaign => handleCompleteSetup(campaign, 0)}
                            onFetchEmailTopics={handleFetchEmailTopics}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                            type="email"
                        />

                    )}
                    {step === 2 && (
                        <Proof
                            email={email}
                            html={previewHtml}
                            onNext={() => setShowPublishEmailDialog(true)}
                            onBack={() => setStep(1)}
                            emailAddresses={proofRecipients}
                            setEmailAddresses={setProofRecipients}
                            type="template"
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={isProofSent}
                            setIsProofSent={setIsProofSent}
                            titleBarDepth={titleBarDepth}
                            onNavigateToEditor={() => setStep(0)}
                            setStep={setStep}
                        />

                    )}
                    {showPublishEmailDialog && (
                        <PublishEmailDialog
                            email={email}
                            onClose={() => setShowPublishEmailDialog(false)}
                            onComplete={handlePublishEmail}
                        />

                    )}
                </>
            )}
        </>
    );
};

export default EmailEditor;
