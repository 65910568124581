import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CenteredProgress = () => {
    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <CircularProgress color="inherit" />
        </Box>
    );
};

export default CenteredProgress;