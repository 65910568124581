import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import StripoEditor from '../stripoEditor/StripoEditor';
import Setup from './views/Setup';
import Publish from './views/Publish';
import useSnackbar from '../hooks/useSnackbar';
import useApi from '../hooks/useApi';
import useTheme from '@mui/material/styles/useTheme';
import { HubConnectionState } from '@microsoft/signalr';
import ContentInUseDialog from './dialogs/ContentInUseDialog';
import useEditorHubConnection from './hooks/useEditorHubConnection';
import AssignQRCode from './views/AssignQRCode';

const drawerWidth = 300;

const steps = [
    { name: 'Editor', description: 'Create your content' },
    { name: 'Setup', description: 'Set your page title and choose topics' },
    { name: 'QR Code', description: 'Assign your page to a QR Code' },
    { name: 'Final Checks', description: 'Last chance to right any wrongs...' }
];

const Page = () => {
    const { id } = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [previewHtml, setPreviewHtml] = useState('');
    const [campaign, setCampaign] = useState(null);
    const { showSnackbar } = useSnackbar();
    const { handleGet, handlePut, handleDelete } = useApi();
    const theme = useTheme();
    const { connection, usersEditing, setUsersEditing } = useEditorHubConnection();
    const [qrCodeContent, setQRCodeContent] = useState(null);

    const titleBarDepth = theme.zIndex.drawer + 1;

    const handleLoadCampaign = async () => {
        const response = await handleGet(`campaigns/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            history.push('/editor');
            return;
        }

        const data = await response.json();
        setCampaign(data);
    };

    const handleFetchCampaignTopics = async () => {
        const response = await handleGet(`campaigns/${id}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const { topics } = await response.json();
        setCampaign({ ...campaign, topics });
    };

    const handleLoad = async () => {
        await handleLoadCampaign();
        setIsLoading(false);
    };

    const handleUpdateCampaign = async () => {
        const response = await handlePut(`campaigns/${id}`, campaign);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        step === 0 && showSnackbar('Changes saved', 'success');
    };

    const handleCompleteEdit = previewHtml => {
        setPreviewHtml(previewHtml);
        setCampaign({
            ...campaign,
            compiledHtml: previewHtml
        });
        setStep(1);
    };

    const handleCompleteSetup = async (newCampaign, step) => {
        setCampaign({ ...campaign, ...newCampaign });
        setStep(step);
    };

    const requestEditorAccess = async () => {
        try {
            await connection.start().then(() => {
                connection.invoke('RequestEditorAccess', parseInt(id));
            }).then(() => {
                connection.invoke('GetEditorsInUseByEmail', parseInt(id));
            });
            connection.on('GetUsersEditing', editorsInUse => {
                setUsersEditing(editorsInUse);
            });
        }
        catch (err) {
            console.error(err);
        }
    };

    const handleDeleteQRCodeContent = async (qrCodeId, qrCodeContentId) => {
        const response = await handleDelete(`qrcodes/${qrCodeId}/content/${qrCodeContentId}`);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        //fetch campaign only for qrcodes
        const campaignResponse = await handleGet(`campaigns/${id}`);

        if (!campaignResponse.ok) {
            console.error(response?.error);
            return;
        }

        const { qrCodes } = await campaignResponse.json();

        setCampaign({ ...campaign, qrCodes });
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }

        handleUpdateCampaign();
    }, [campaign]);

    useEffect(() => {
        if (connection && connection.state !== HubConnectionState.Connected) {
            requestEditorAccess();
        }
    }, [connection]);

    useEffect(() => {
        handleLoad();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            {!!usersEditing.length && <ContentInUseDialog usersEditing={usersEditing} type="page" />}
            {step === 0 ? (
                <StripoEditor
                    email={campaign}
                    onUpdateEmail={props => setCampaign({ ...campaign, ...props })}
                    onNext={handleCompleteEdit}
                    type="page"
                />
            ) : (
                <>
                    {step === 1 && (
                        <Setup
                            email={campaign}
                            onUpdateEmail={props => setCampaign({ ...campaign, ...props })}
                            onNext={campaign => handleCompleteSetup(campaign, 2)}
                            onBack={campaign => handleCompleteSetup(campaign, 0)}
                            onFetchEmailTopics={handleFetchCampaignTopics}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={false}
                            audienceName={null}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                            type="page"
                        />
                    )}
                    {step === 2 && (
                        <AssignQRCode
                            qrCodeContent={qrCodeContent}
                            setQRCodeContent={setQRCodeContent}
                            onNext={() => setStep(3)}
                            onBack={() => setStep(1)}
                            drawerWidth={drawerWidth}
                            steps={steps}
                            step={step}
                            campaign={campaign}
                            isProofSent={false}
                            audienceName={null}
                            titleBarDepth={titleBarDepth}
                            setStep={setStep}
                            onDeleteQRCodeContent={handleDeleteQRCodeContent}
                            contentType="Landing Page"
                        />
                    )}
                    {step === 3 && (
                        <Publish
                            qrCodeContent={qrCodeContent}
                            campaign={campaign}
                            onBack={() => setStep(2)}
                            previewHtml={previewHtml}
                            steps={steps}
                            step={step}
                            setStep={setStep}
                            drawerWidth={drawerWidth}
                            titleBarDepth={titleBarDepth}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Page;