import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import PermissionsPicker from './PermissionsPicker';

const EditUserDialog = ({ user, onSubmit, onClose }) => {
    const [selectedPermissions, setSelectedPermissions] = useState(user.permissions.map(({ id }) => ({ id })));
    const [name, setName] = useState(user.name ?? '');

    const handleSubmit = () => {
        onSubmit({ permissions: selectedPermissions, name });
    };

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <TextField
                        autoFocus
                        fullwidth
                        variant="outlined"
                        size="small"
                        label="Name"
                        autoComplete="off"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormControl>
                <Typography gutterBottom>
                    Change the permissions of <strong>{user.emailAddress}</strong>.
                </Typography>
                <PermissionsPicker
                    selectedPermissions={selectedPermissions}
                    onChange={setSelectedPermissions}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Save</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;