import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Snackbar from '@mui/material/Snackbar';

const PublishedDialog = ({ onClose, report }) => {
    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setSnackbarMessage('Link copied to clipboard');
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={onClose}
            >
                <DialogTitle>Congratulations - Landing Page Published</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ marginBottom: 2 }}>
                        Your Landing Page is now available to view online via the link below. Click Copy to copy this to your clipboard.
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Button variant="text" href={report.shareLink} target="_blank" title={report.subject}>
                            {report.subject}
                        </Button>
                        <IconButton
                            aria-label="copy to clipboard"
                            onClick={() => copyToClipboard(report.shareLink)}
                        >
                            <ContentPasteIcon />
                        </IconButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={Boolean(snackbarMessage)}
                onClose={() => setSnackbarMessage(null)}
                autoHideDuration={3000}
                message={snackbarMessage}
            />
        </>
    );
};

export default PublishedDialog;