/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const StartItem = ({ imgSrc, title, href, buttonText }) => {
    return (
        <Card>
            <CardActionArea href={href}>
                <CardContent>
                    <Box component="img" src={imgSrc} sx={{ marginBottom: 1 }} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            component="p"
                            variant="body1"
                            gutterBottom
                        >
                            {title}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    fullWidth
                    href={href}
                >
                    {buttonText}
                </Button>
            </CardActions>
        </Card>
    );
};

const Iframe = props => {
    const ref = useRef(null);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const ratio = 0.5625;

    const handleResizeIframe = () => {
        if (!ref.current) {
            return;
        }

        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetWidth * ratio);
    };

    useEffect(() => {
        handleResizeIframe();
        window.addEventListener('resize', handleResizeIframe);
        return () => window.removeEventListener('resize', handleResizeIframe);
    }, []);

    return (
        <div ref={ref}>
            <iframe
                {...props}
                width={width}
                height={height}
                style={{ display: 'block' }}
            />
        </div>
    );
};

const Start = () => {
    return (
        <Box sx={{ margin: 3 }}>
            <Grid container spacing={3}>
                <Grid xs={7} container item spacing={3}>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/get-started.svg"
                            title="Create &amp; Send with our easy to use editor"
                            href="#/editor"
                            buttonText="Create &amp; Send"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/view-reports.svg"
                            title="Track your campaign progress and engagement"
                            href="#/reports"
                            buttonText="View Reports"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/import-contacts.svg"
                            title="Manage contacts, groups and segments"
                            href="#/contacts"
                            buttonText="Contacts"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StartItem
                            imgSrc="/images/audit-trail.svg"
                            title="Manage accountability with multi-user audit trail"
                            href="#/account/auditing"
                            buttonText="Audit Trail"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Card>
                        <Iframe
                            src="https://www.youtube.com/embed/videoseries?list=PLHN7sO5bejWtQFpBs8_G8fnc09piRlGgf"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Start;