import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import buildUrl from '../../buildUrl';
import useApi from '../../hooks/useApi';
import NoRows from '../../dataTable/NoRows';
import { styled } from '@mui/material/styles';

const rowsPerPageOptions = [10, 20, 50];

const campaignColumns = [
    { field: 'name', headerName: 'Name', orderDesc: false, type: 'string', flex: 0.75 },
    { field: 'subject', headerName: 'Subject Line', orderDesc: false, type: 'string', flex: 1 },
    { field: 'sendDateTime', headerName: 'Send Date', orderDesc: true, type: 'dateTime', flex: 0.35, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'selected', headerName: 'Sent To', orderDesc: false, type: 'number' }
];

const pageColumns = [
    { field: 'name', headerName: 'Name', orderDesc: false, type: 'string', flex: 0.75 },
    { field: 'subject', headerName: 'Page Title', orderDesc: false, type: 'string', flex: 1 },
    { field: 'sendDateTime', headerName: 'Publish Date', orderDesc: true, type: 'dateTime', flex: 0.35, valueGetter: ({ value }) => value && new Date(value) }
];

const surveyColumns = [
    { field: 'name', headerName: 'Name', orderDesc: false, type: 'string', flex: 0.75 },
    { field: 'sendDateTime', headerName: 'Created Date', orderDesc: true, type: 'dateTime', flex: 0.35, valueGetter: ({ value }) => value && new Date(value) },
    { field: 'modified', headerName: 'Modified Date', orderDesc: true, type: 'dateTime', flex: 0.35, valueGetter: ({ value }) => value && new Date(value) }
];

const StyledDataGridPro = styled(DataGridPro)(() => ({
    '.MuiDataGrid-cell:focus': {
        outline: 'none'
    },
    // pointer cursor on ALL rows
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer'
    }
}));

const ContentSelect = ({ type, onSetCampaign, currentlySelected, currentlySelectedSurvey = null }) => {
    const { handleGet } = useApi();
    const [campaigns, setCampaigns] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [campaignCount, setCampaignCount] = useState(0);
    const [surveyCount, setSurveyCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [orderDesc, setOrderDesc] = useState(true);
    const [orderBy, setOrderBy] = useState('sendDateTime');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [columns, setColumns] = useState(campaignColumns);

    const handleSelectCampaign = (e, campaign) => {
        e.preventDefault();
        onSetCampaign(campaign);
    };

    const handleFetchCampaigns = async () => {
        const params = {
            type,
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy,
            isDescending: orderDesc,
            ...(type === 'page' && { published: true })
        };

        const url = buildUrl('reports', params);

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setCampaigns(data);
        setIsLoading(false);
    };

    const handleFetchCampaignsCount = async () => {
        const url = buildUrl('reports/count', {
            type,
            ...(type === 'page' && { published: true })
        });

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const count = await response.json();

        setCampaignCount(count);
    };

    const handleFetchSurveys = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy: 'Modified',
            isDescending: orderDesc,
            status: 'Active'
        };

        const url = buildUrl('surveys', params);

        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setSurveys(data);
        setIsLoading(false);
    };

    const handleFetchSurveyCount = async () => {
        const params = {
            skip: page * rowsPerPage,
            take: rowsPerPage,
            orderBy: 'Modified',
            isDescending: orderDesc,
            status: 'Active'
        };

        const url = buildUrl('surveys/count', params);
        const response = await handleGet(url);

        if (!response.ok) {
            console.error(response?.error);
            return;
        }

        const data = await response.json();

        setSurveyCount(data);
    };

    const handleSort = props => {
        const { field, sort } = props[0];

        if (field === orderBy) {
            setOrderDesc(!orderDesc);
        }
        else {
            setOrderDesc(sort === 'desc');
            setOrderBy(field);
        }
    };

    useEffect(() => {
        if (currentlySelected && campaigns.length > 0) {
            setSelectedIds([currentlySelected.id]);
        }
    }, [campaigns, currentlySelected]);

    useEffect(() => {
        if (currentlySelectedSurvey && surveys.length > 0) {
            setSelectedIds([currentlySelectedSurvey.id]);
        }
    }, [campaigns, currentlySelected]);

    useEffect(() => {
        setPage(0);
    }, [orderBy, orderDesc, rowsPerPage]);

    useEffect(() => {
        setIsLoading(true);
    }, [orderBy, orderDesc, rowsPerPage, page, type]);

    useEffect(() => {
        if (isLoading) {
            handleFetchCampaigns();
            handleFetchCampaignsCount();
            handleFetchSurveys();
            handleFetchSurveyCount();
        }
    }, [isLoading]);

    useEffect(() => {
        handleFetchCampaignsCount();
        handleFetchSurveyCount();
    }, []);

    useEffect(() => {
        if (type === 'email') {
            setColumns(campaignColumns);
        }
        else if (type === 'page') {
            setColumns(pageColumns);
        }
        else {
            setColumns(surveyColumns);
        }
    }, [type]);

    const sort = orderDesc ? 'desc' : 'asc';

    return (
        <StyledDataGridPro
            autoHeight
            disableColumnMenu
            disableColumnFilter
            disableMultipleColumnsSorting
            disableMultipleSelection
            hideFooterSelectedRowCount
            pagination
            paginationMode="server"
            sortingMode="server"
            rows={type === 'survey' ? surveys : campaigns}
            rowCount={type === 'survey' ? surveyCount : campaignCount}
            rowHeight={32}
            columns={columns}
            loading={isLoading}
            pageSize={rowsPerPage}
            onPageSizeChange={rowsPerPage => setRowsPerPage(rowsPerPage)}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={page => setPage(page)}
            onSortModelChange={props => handleSort(props)}
            onRowClick={(value, e) => handleSelectCampaign(e, value.row)}
            selectionModel={selectedIds}
            sortingOrder={['desc', 'asc']}
            initialState={{
                sorting: {
                    sortModel: [{ field: orderBy, sort }]
                }
            }}
            components={{
                NoRowsOverlay: NoRows
            }}
            sx={{ borderRadius: 0 }}
        />
    );
};

export default ContentSelect;