import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingOverlay from '../../loadingOverlay/LoadingOverlay';
import useSnackbar from '../../hooks/useSnackbar';
import useAccount from '../../hooks/useAccount';

const types = [
    'string',
    'number',
    'bool',
    'date',
    'phone',
    'email'
];

// todo type display name could be moved into types

const getType = type => {
    switch (type) {
        case 'string': return 'Text';
        case 'number': return 'Number';
        case 'bool': return 'Yes/No';
        case 'date': return 'Date & Time';
        case 'phone': return 'Telephone Number';
        case 'email': return 'Email Address';
    }

    return type;
};

const AddFieldDialog = ({ onSubmit, onClose }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState(types[0]);
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const { handleAddField } = useAccount();

    const handleSubmitAddField = async field => {
        setIsLoading(true);

        const response = await handleAddField(field);

        if (!response.ok) {
            showSnackbar(response.status === 409 ? 'A Custom Field with this name and type already exists' : response.statusText, 'error');
            setIsLoading(false);
        }

        onSubmit(field);
    };

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogTitle>Add Custom Field</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are about to create a custom field. You can use these fields in your Contacts section and for personalising your email campaigns. Custom fields can also be created in your Contacts section during the Import process when using the Upload File option.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    margin="dense"
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Data Type</InputLabel>
                    <Select value={type} onChange={e => setType(e.target.value)} label="Data Type">
                        {types.map(t => (
                            <MenuItem key={t} value={t}>
                                {getType(t)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSubmitAddField({ name, type })} disabled={!name}>Add Field</Button>
                <Button variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFieldDialog;