import React, { useEffect, useState } from 'react';
import { Paper, Toolbar, Button, Typography, Divider, Link, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TitleBar from '../../titleBar/TitleBar';
import AddIcon from '@mui/icons-material/Add';
import useApi from '../../hooks/useApi';
import AddFeatureModel from './modals/AddFeatureModel';
import TableContainer from '@mui/material/TableContainer';
import useSnackbar from '../../hooks/useSnackbar';
import EditFeature from './modals/EditFeature';
import ScreenshotModal from './modals/ScreenshotModal';
import DeleteFeatureModal from './modals/DeleteFeatureModal';

const Features = ({ showHamburger, onClickHamburger }) => {
    const { handleGet, handlePut, handlePost, handleFetch, handleDelete } = useApi();
    const [features, setFeatures] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);
    const [showEditFeatureDialog, setShowEditFeatureDialog] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [showDeleteFeatureModal, setDeleteFeatureModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    const cellStyles = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    };

    const showEditFeatureModal = (data) => {
        setShowEditFeatureDialog(true);
        setSelectedFeature(data);
    };

    const handleDeleteFeatureModal = (data) => {
        setDeleteFeatureModal(true);
        setSelectedFeature(data);
    };

    const handleHideDeleteFeatureModal = () => {
        setDeleteFeatureModal(false);
        setSelectedFeature(null);
    };

    const handleEditFeatureDialog = () => {
        setShowEditFeatureDialog(false);
        setSelectedFeature(null);
    };

    const handleShowScreenshotDialog = (data) => {
        setShowScreenshotModal(true);
        setSelectedFeature(data);
    };

    const handleHideShowScreenshotDialog = () => {
        setShowScreenshotModal(false);
        setSelectedFeature(null);
    };

    const fetchFeatures = async () => {
        try {
            const response = await handleGet('superadmin/accounts/features');
            const data = await response.json();
            setFeatures(data);
        }
        catch (error) {
            showSnackbar('An error occurred.', 'error');
        }
    };

    const handleCreateFeature = async (data) => {
        setIsLoading(true);

        const uri = '/superadmin/accounts/features';
        const response = await handlePost(uri, data);

        setIsLoading(false);

        if (!response.ok) {
            try {
                const errorMessage = await response.json();

                if (errorMessage.message) {
                    showSnackbar(`${errorMessage.message}`, 'error');
                }
                else {
                    showSnackbar('An error occurred.', 'error');
                }
            }
            catch (error) {
                showSnackbar('An error occurred.', 'error');
            }
        }
        else {
            showSnackbar('Feature created successfully.', 'success');
            setShowCreateAccountDialog(false);
            await fetchFeatures();
        }
    };

    const handleEditFeature = async (data) => {
        setIsLoading(true);

        const uri = `/superadmin/accounts/features/${data.id}`;
        const response = await handlePut(uri, data);

        setIsLoading(false);

        if (!response.ok) {
            try {
                const errorMessage = await response.json();

                if (errorMessage.message) {
                    showSnackbar(`${errorMessage.message}`, 'error');
                }
                else {
                    showSnackbar('An error occurred.', 'error');
                }
            }
            catch (error) {
                showSnackbar('An error occurred.', 'error');
            }
        }
        else {
            showSnackbar('Feature edited successfully.', 'success');
            handleEditFeatureDialog();
            await fetchFeatures();
        }
    };

    const handleDeleteFeature = async (data) => {
        setIsLoading(true);
        const response = await handleDelete(`superadmin/accounts/features/${data.id}`);

        if (!response.ok) {
            showSnackbar('An error occurred.', 'error');
        }
        else {
            showSnackbar('Feature deleted successfully', 'success');
        }

        setDeleteFeatureModal(false);
        await fetchFeatures();
    };

    const handleScreenshot = async (data) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', data[0]);

        const response = await handleFetch(`superadmin/accounts/features/${selectedFeature.id}/feature-image`, {
            method: 'post',
            body: formData
        });

        setIsLoading(false);

        if (!response.ok) {
            try {
                const errorMessage = await response.json();

                if (errorMessage.message) {
                    showSnackbar(`${errorMessage.message}`, 'error');
                }
                else {
                    showSnackbar('An error occurred.', 'error');
                }
            }
            catch (error) {
                showSnackbar('An error occurred.', 'error');
            }
        }
        else {
            showSnackbar('Screenshot uploaded successfully.', 'success');
            handleHideShowScreenshotDialog();
            await fetchFeatures();
        }
    };

    useEffect(() => {
        fetchFeatures();
    }, []);

    return (
        <>
            <TitleBar showHamburger={showHamburger} onClickHamburger={onClickHamburger}>
                <Breadcrumbs>
                    <Link href="/#/superadmin">Super Admin</Link>
                    <Typography>
                        <strong>Features</strong>
                    </Typography>
                </Breadcrumbs>
            </TitleBar>

            <Paper sx={{ m: 2 }}>
                <Toolbar
                    disableGutters
                    sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 1 }}
                >
                    <Typography variant="h6" sx={{ marginLeft: 3 }}>Features</Typography>
                    <Button
                        sx={{ ml: 1 }}
                        startIcon={<AddIcon />}
                        onClick={() => setShowCreateAccountDialog(true)}
                    >
                        New Feature
                    </Button>
                </Toolbar>
                <Divider />

                {features.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" sx={cellStyles}>Feature Name</TableCell>
                                    <TableCell component="th" sx={cellStyles}>Feature Description</TableCell>
                                    <TableCell component="th" sx={cellStyles}>Created Date</TableCell>
                                    <TableCell component="th" sx={cellStyles}>Level</TableCell>
                                    <TableCell component="th" sx={cellStyles} align="right" />
                                    <TableCell component="th" sx={cellStyles} align="right" />
                                    <TableCell component="th" sx={cellStyles} align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {features.map((feature) => (
                                    <TableRow key={feature.id}>
                                        <TableCell sx={{ ...cellStyles, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '250px' }}>{feature.name}</TableCell>
                                        <TableCell sx={{ ...cellStyles, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '400px' }}>{feature.description}</TableCell>
                                        <TableCell sx={cellStyles}>{new Date(feature.createdDateTime).toLocaleDateString()}</TableCell>
                                        <TableCell sx={cellStyles}>{feature.level}</TableCell>
                                        <TableCell sx={cellStyles} align="right">
                                            <Link onClick={() => showEditFeatureModal(feature)} title="Edit Feature" style={{ cursor: 'pointer' }}>
                                                Edit
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={cellStyles} align="right">
                                            <Link onClick={() => handleDeleteFeatureModal(feature)} title="Delete Feature" style={{ cursor: 'pointer' }}>
                                                Delete
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={cellStyles} align="right">
                                            <Link onClick={() => handleShowScreenshotDialog(feature)} title="Edit Screenshot" style={{ cursor: 'pointer' }}>
                                                Add/Edit Screenshot
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

            </Paper>

            {showCreateAccountDialog && (
                <AddFeatureModel
                    shown={showCreateAccountDialog}
                    onHide={() => setShowCreateAccountDialog(false)}
                    onSubmit={handleCreateFeature}
                    onLoading={isLoading}
                />
            )}

            {showEditFeatureDialog && (
                <EditFeature
                    shown={showEditFeatureDialog}
                    onHide={handleEditFeatureDialog}
                    onSubmit={handleEditFeature}
                    onLoading={isLoading}
                    data={selectedFeature}
                />
            )}

            {showScreenshotModal && (
                <ScreenshotModal
                    shown={showScreenshotModal}
                    onHide={handleHideShowScreenshotDialog}
                    data={selectedFeature}
                    onLoading={isLoading}
                    onSubmit={handleScreenshot}
                />
            )}
            {showDeleteFeatureModal && (
                <DeleteFeatureModal
                    shown={showDeleteFeatureModal}
                    onHide={handleHideDeleteFeatureModal}
                    data={selectedFeature}
                    onSubmit={handleDeleteFeature}
                />
            )}
        </>
    );
};

export default Features;